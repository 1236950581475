import { AnimationOnScroll } from 'react-animation-on-scroll';

const Developers = () => {

  return (
    <AnimationOnScroll animateIn="animate__fadeInUp" delay={500} style={{ minHeight: '250px', marginBottom: '50px' }}>
      <section id="developers" className="container section">
        <div className="section-title text-center">
          <h2>Developers</h2>
          <p className="mt50">Coming Soon</p>
        </div>
      </section>
    </AnimationOnScroll>
  )
}

export default Developers;