import { useEffect, useState } from 'react';
import axios from 'axios';

export default function AppWs(props) {
  const [isPaused, setPause] = useState(false);
  const [ws, setWs] = useState(null);
  const [data, setData] = useState([])
  const [diff, setDiff] = useState([])
  const [diffColor, setDiffColor] = useState()

  const [id, setId] = useState(props.coin)
  
  useEffect(() => {
    console.log('props: ', props.coin)
    const getData = async () => {
      axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms=' + props.coin + '&tsyms=USD')
      .then(response => {
        // console.log('COINzzzzz: ', response.data)
        setData((response.data.RAW[id].USD.PRICE))
        setDiff((response.data.DISPLAY[id].USD.CHANGEPCT24HOUR))
        if(response.data.DISPLAY[id].USD.CHANGEPCT24HOUR.charAt(0) === '-') {
          setDiffColor('red')
        } else {
          setDiffColor('green')
        }
      })
      .catch(err => {
        console.log('Error-2023: ', err)
      })
    }

    setInterval(function(){ getData() }, 5000);
    // const wsClient = new WebSocket('wss://streamer.cryptocompare.com/v2?api_key=06c7d191b8cb7523c80f50653cbc5ff172c88f3d1d74959d21ebcf1ef605ab33');
    // const subRequest = {
    //   "action": "SubAdd",
    //   // "subs": ["5~CCCAGG~BTC~USD", "0~Coinbase~ETH~USD", "2~Binance~BTC~USDT"],
    //   "subs": [
    //     "2~Binance~" + props.coin + "~USD"
    //   ]
    // };
    // wsClient.onopen = () => {
    //   console.log('ws opened');
    //   wsClient.send(JSON.stringify(subRequest))
    //   setWs(wsClient);
    // };
    // wsClient.onclose = () => console.log('ws closed');

    // return () => {
    //   wsClient.close();
    // }
  }, []);

  // useEffect(() => {
  //   if (!ws) return;

  //   ws.onmessage = e => {
  //     if (isPaused) return;
  //     const message = JSON.parse(e.data);
  //     setData(message)
  //     console.log('e: ', message);
  //   };
  // }, [isPaused, ws]);


  return (
    <>
    
      {/* <button onClick={() => setPause(!isPaused)}>{isPaused ? 'Resume' : 'Pause'}</button> */}
    
    {/* <div class="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center space-x-4"> */}

    
    <span className="bold">${data}</span>
    {/* <span className="ml25 bold" style={{color: diffColor}}>{diff}%</span> */}
                      
   
    {/* <div>
      <div class="text-xl font-medium text-black">LLAMA</div>
      <p class="text-gray-500"> Symbol: {data.FROMSYMBOL} - {data.PRICE}</p>
    </div> */}
  {/* </div> */}
    </>
  )
}