import { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import { url_rest, api_key } from '../../api/cryptocompare';
import {AgGridColumn, AgGridReact, cellRendererFramework} from 'ag-grid-react';
import abbreviate from 'number-abbreviate';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import CoinChart from './CoinChart';
import TopChange from './CoinsTopChange';
import BottomChange from './CoinsBottomChange';
import Top10 from './SocialTop10';
import useStickyHeader from "./useStickyHeader";
import './Coins.scss';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import contactlogo from '../../assets/llama_green_500.png';

const StickyText = ({ stickyRef }) => {
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    window.addEventListener("scroll", forceUpdate);
    return () => window.removeEventListener("scroll", forceUpdate);
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        zIndex: 10000,
        backgroundColor: stickyRef.current ? "green" : "red",
        color: "white"
      }}
    >
      {/* Is header sticky: {stickyRef.current.toString()} */}
    </div>
  );
};

const CoinList = () => {

  const [coins, setCoins] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [pageSize, setPageSize] = useState(100);
  const [rowData, setRowData] = useState([]);

  const [getTopCoins, setGetTopCoins] = useState([]);
  const [getBottomCoins, setGetBottomCoins] = useState([]);
  const [query, setQuery] = useState('USD');

  const stickyRef = useStickyHeader();

  

  // useEffect(() => {
  //   // axios.get(url_rest + '/all/coinlist?api_key=' + api_key)
  //   axios.get('https://min-api.cryptocompare.com/data/all/coinlist?api_key=193ab8d36ace770ec86eb59b5997326a5b81da049351bcc1fcd41cc031bda67c')
  //   .then(response => {
  //     console.log('COINS: ', response);
  //     Object.keys(response.data.Data).forEach(function(key) {
  //       getCoins.push(response.data.Data[key]);
  //     });

  //     setCoins(getCoins)
  //   })
  //   .catch(err => {
  //     console.log('Error: ', err)
  //   })
  // },[])

  const getCoins = [];

  useEffect(() => {
    // setGridApi(params.api);
    // setGridColumnApi(params.columnApi);

    // params.api.showLoadingOverlay();

    console.log('setQuery: ', query)

    axios.get('https://min-api.cryptocompare.com/data/top/totalvolfull?limit=100&tsym='+  query + '&api_key=ca0c75e0493e1c7b3016881cc0b6ca67f9195360661843f2ff3fa50675812b26')
    .then(response => {
      console.log('COINS: ', response);
      // Object.keys(response.data.Data).forEach(function(key) {
        setRowData(response.data.Data);
        getCoins.push(response.data.Data);
      // getCoins.sort((a, b) => parseInt(a.SortOrder) - parseInt(b.SortOrder)).map((sort) => {
      //   orderCoins.push(sort)
      // })
      // })
      // params.api.setRowData(getCoins);

      // console.log('getCoins: ', getCoins);
      // console.log('GET!!!! rowData: ', rowData);


    })
    .catch(err => {
      console.log('Error: ', err)
    })
  }, [query]);

  const setTopCoins = [];
  const setBottomCoins = [];
  const TopCoins = []

  useEffect(() => {

    
    
    // setTimeout(() => {
      
      rowData
      .sort(function(a, b){
        if("RAW" in a && "RAW" in b){
          console.log('QUERY: ', query);
            if(query === 'USD') {
            return b.RAW.USD.CHANGEPCT24HOUR - a.RAW.USD.CHANGEPCT24HOUR
            } else if (query === 'USDS') {
              return b.RAW.USDS.CHANGEPCT24HOUR - a.RAW.USDS.CHANGEPCT24HOUR
            }  else if (query === 'BTC') {
              return b.RAW.BTC.CHANGEPCT24HOUR - a.RAW.BTC.CHANGEPCT24HOUR
            }
        }
      })
      .slice(0, 4)
      .map((change) => {
        // if(change.USD) {
          if(query === 'USD') {
          return (
          setTopCoins.push(
            {
              FullName: change.CoinInfo.FullName,
              Name: change.CoinInfo.Name,
              Change: change.RAW.USD.CHANGEPCT24HOUR,
              Img: change.CoinInfo.ImageUrl,
              Price: change.RAW.USD.PRICE      
            }
          )
          )
        } else if (query === 'USDS') {
          return (
            setTopCoins.push(
              {
                FullName: change.CoinInfo.FullName,
                Name: change.CoinInfo.Name,
                Change: change.RAW.USDS.CHANGEPCT24HOUR,
                Img: change.CoinInfo.ImageUrl,
                Price: change.RAW.USDS.PRICE      
              }
            )
            )
        } else if (query === 'BTC') {
          return (
            setTopCoins.push(
              {
                FullName: change.CoinInfo.FullName,
                Name: change.CoinInfo.Name,
                Change: change.RAW.BTC.CHANGEPCT24HOUR,
                Img: change.CoinInfo.ImageUrl,
                Price: change.RAW.BTC.PRICE      
              }
            )
          )
        }
        // }
      })

      rowData
      .sort(function(a, b){
        if("RAW" in a && "RAW" in b){
          if(query === 'USD') {
          return a.RAW.USD.CHANGEPCT24HOUR - b.RAW.USD.CHANGEPCT24HOUR
          } else if (query === 'USDS') {
            return b.RAW.USDS.CHANGEPCT24HOUR - a.RAW.USDS.CHANGEPCT24HOUR
          }  else if (query === 'BTC') {
            return b.RAW.BTC.CHANGEPCT24HOUR - a.RAW.BTC.CHANGEPCT24HOUR
          }
      }
      })
      .slice(0, 4)
      .map((change) => {
        if(query === 'USD') {
          return (
          setBottomCoins.push(
            {
              FullName: change.CoinInfo.FullName,
              Name: change.CoinInfo.Name,
              Change: change.RAW.USD.CHANGEPCT24HOUR,
              Img: change.CoinInfo.ImageUrl,
              Price: change.RAW.USD.PRICE      
            }
          )
          )
        } else if (query === 'USDS') {
          return (
            setBottomCoins.push(
              {
                FullName: change.CoinInfo.FullName,
                Name: change.CoinInfo.Name,
                Change: change.RAW.USDS.CHANGEPCT24HOUR,
                Img: change.CoinInfo.ImageUrl,
                Price: change.RAW.USDS.PRICE      
              }
            )
            )
        } else if (query === 'BTC') {
          return (
            setBottomCoins.push(
              {
                FullName: change.CoinInfo.FullName,
                Name: change.CoinInfo.Name,
                Change: change.RAW.BTC.CHANGEPCT24HOUR,
                Img: change.CoinInfo.ImageUrl,
                Price: change.RAW.BTC.PRICE      
              }
            )
          )
        }
      })

    console.log('setTopCoins: ', setTopCoins)

    setTimeout(() => {setGetTopCoins(setTopCoins)}, 1000)
    setTimeout(() => {setGetBottomCoins(setBottomCoins)}, 1000)

    console.log('rowData: ', rowData)
      },[rowData, query])

    // }, 1000);

  console.log('getTopCoins222: ', getTopCoins)

  const ShowTopChange = () => {
    setTimeout(() => {
      return <TopChange top={getTopCoins} />
    }, 2000)

    return <TopChange top={getTopCoins} />
  }

  const ShowBottomChange = () => {
    setTimeout(() => {
      return <BottomChange top={getBottomCoins} />
    }, 2000)

    return <BottomChange top={getBottomCoins} />
  }

  // needs work still - going with column fikltering for now


  const QueryType = (qtype) => {
    setQuery(qtype);
    console.log(qtype)
  }

  const utcDate1 = new Date().toUTCString();

  console.log(utcDate1)

  return (
    <div className="one socialmedia toplist">
    <section id="llama">
    <div className="container">
        <div className="row">
        <div className="col text-center">
        <h1 className="token">Llama Top <span style={{fontSize: '45px', paddingLeft: '10px'}}>10</span> Gainers
        <small style={{display: 'block', fontSize: '18px', fontFamily: 'sans-serif', fontWeight: '100', color: 'rgba(255,255,255,.5)', textTransform: 'none'}}>
          {utcDate1}
          </small>
        </h1>
        <div className='llamaCircle'></div>
        <img className="llamaLogo" src={contactlogo} />
        {/* <p className="text">
        <div className="highlight">Llama is a new cryptocurrency meme token that is all set to take the world by storm.</div> 
        <div className="sub">
            <div><span>Llama is an innovative token that is built with a primary focus on providing a fun and lighthearted experience to its users.</span> It is inspired by the meme culture and aims to offer a more casual and playful approach to the traditional cryptocurrency world. The Llama team has put in extensive effort to create a user-friendly platform that is easy to navigate, even for those who are new to the cryptocurrency space.</div>
            <a className="btn btn-sm btn-primary" href="#about">About<i className="fa-solid fa-angle-right ml10"></i></a> <a className="btn btn-sm btn-light ml15" href="./LLamaWhitePaper.pdf" download>White Paper <i className="fa-solid fa-angle-right ml10"></i></a>
        </div>
        </p> */}
        </div>
    </div>
    </div>
   </section>
    {/* <ShowTopChange />
    <ShowBottomChange /> */}
    <Container>
    <div className="ag-theme-alpine mt25 mb100" style={{height: '200%', width: '100%', minHeight: '100vh', marginBottom: '100px'}}>
    
    
    
    <StickyText stickyRef={stickyRef} />
    
    <div className="GridMain">
  
      <Row className="GridHeader">
      </Row>
    

    <Top10 />

    </div>
   
    </div>
    </Container>
    </div>
    
  )
}

export default CoinList;