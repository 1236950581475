import { useState, useEffect} from "react";
import { Card, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import AppWs from './Websockets';
import CoinChart from './CoinChartDetails';
// import CoinChartFin from './CoinChartFin/chart'
import News from './News';

const Coin = ({match}) => {

  

  const [getCoin, setGetCoin] = useState([]);
  const [getCoinDetails, setGetCoinDetails] = useState([]);
  const [id, setId] = useState(match.params.id)

  useEffect(() => {
    console.log('ID: ', id)
    const getData = async () => {
    // axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms=' + id + '&tsyms=USD&api_key=06c7d191b8cb7523c80f50653cbc5ff172c88f3d1d74959d21ebcf1ef605ab33')
    axios.get('https://min-api.cryptocompare.com/data/all/coinlist?fsym=' + id + '&api_key=ca0c75e0493e1c7b3016881cc0b6ca67f9195360661843f2ff3fa50675812b26')
    //  .then((resp) => resp)
     .then((response) => {
      console.log('COIN: ', response)
      response.data.Response != 'Error' ? 
      setGetCoin(response.data.Data) 
       : console.log('ERROR: ', response.data.Message)
     })
    }

    setTimeout(function(){ getData() }, 100);

  }, [])

  useEffect(() => {
    const getFullData = async () => {
    axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms=' + id + '&tsyms=USD&api_key=ca0c75e0493e1c7b3016881cc0b6ca67f9195360661843f2ff3fa50675812b26')
    // axios.get('https://min-api.cryptocompare.com/data/all/coinlist?fsym=' + id + '&api_key=06c7d191b8cb7523c80f50653cbc5ff172c88f3d1d74959d21ebcf1ef605ab33')
    //  .then((resp) => resp)
     .then((response) => {
      console.log('FULL: ', response)
      response.data.Response != 'Error' ? 
      setGetCoinDetails(response.data.RAW[id]) 
       : console.log('ERROR: ', response.data.Message)
     })
    }

    setTimeout(function(){ getFullData() }, 100);

  }, [])

  console.log('State: ', getCoinDetails.USD)

  const MarketData = () => (
    <>
    <Card className="mt25">
      <Card.Body>
        {/* <Card.Title className="mb40">Market Data</Card.Title> */}
        <div className="row">
          <Col>
            <Card.Text style={{fontSize: '14px'}}><h5 style={{fontSize: '16px'}}>Market Cap</h5> ${getCoinDetails.USD && getCoinDetails.USD.MKTCAP}</Card.Text>
          </Col>
          <Col>
            <Card.Text style={{fontSize: '14px'}}><h5 style={{fontSize: '16px'}}>24 Hr Volume</h5> ${getCoinDetails.USD && getCoinDetails.USD.VOLUME24HOUR * getCoinDetails.USD.PRICE}</Card.Text>
          </Col>
          <Col>
            <Card.Text style={{fontSize: '14px'}}><h5 style={{fontSize: '16px'}}>Circulating Supply</h5> {getCoinDetails.USD && getCoinDetails.USD.CIRCULATINGSUPPLY}</Card.Text>
          </Col>
          <Col>
            <Card.Text style={{fontSize: '14px'}}><h5 style={{fontSize: '16px'}}>Rank</h5> {getCoin[id] && getCoin[id].SortOrder}</Card.Text>
          </Col>
        </div>
        
      </Card.Body>
    </Card>
    </>
  )
  
    console.log('getcoin: ', getCoin[id])
  return (
    <div className="container mb50">
    {getCoin[id] && <h1 style={{fontSize: '2rem'}}> <img src={`http://cryptocompare.com/${getCoin[id].ImageUrl}`} style={{width: '50px', marginTop: '-5px', marginRight: '10px'}} /> {getCoin[id].FullName} </h1>}
      <span className="bold">Current Price: <AppWs coin={match.params.id} /></span>

      <div id="CoinChartFin">
      <CoinChart
        coin={match.params.id}
      />
      </div>
      
      <div className="row mt25">
        <div className="col-xs-12">
          <MarketData />

          <Card>     
            <Card.Body>
              <Card.Title className="mb20" style={{fontSize: '16px'}}>Details</Card.Title>
              <Card.Text style={{fontSize: '14px', lineHeight: '28px'}}>{getCoin[id] && getCoin[id].Description}</Card.Text>
            </Card.Body>
          </Card>

          <News coin={id}/>
        </div>
        {/* <div className="col-lg-2 mt25">
          
              <a href="https://shop.ledger.com/pages/ledger-nano-x?r=b64650b3e27a">
                <img width='100%' src="http://affiliate.ledger.com/image/300/250" /></a>
            
        </div> */}
      
    </div>
    </div> 
  ) 
}

export default Coin;