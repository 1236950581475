import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { auth, signInWithEmailAndPassword, signInWithGoogle } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "./Account.scss";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading] = useAuthState(auth);
  // const history = useHistory();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      console.log('User: ', user)
      sessionStorage.setItem('userid', user.uid)
      sessionStorage.setItem('name', user.displayName)
      // history.replace("/");
      window.location = ('/') 
    }
  }, [user, loading]);
  return (
    <AnimationOnScroll animateIn="animate__fadeIn" delay={100}>
      <div className="container">
      <div className="row">
        <div className="col-6">
        <h2>Login</h2>
        <small></small>
      </div></div>
      </div>
    <div className="login">
      <div className="login__container">
      <h5>Llama - Login</h5>
        <input
          type="text"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button
          className="login__btn"
          onClick={() => signInWithEmailAndPassword(email, password)}
        >
          Login
        </button>
        <button className="login__btn login__google" onClick={signInWithGoogle}>
          Login with Google
        </button>
        <div>
          <Link to="/reset">Forgot Password</Link>
        </div>
        <div>
          Don't have an account? <Link to="/register">Register</Link> now.
        </div>
      </div>
    </div>
    </AnimationOnScroll>
  );
}
export default Login;
