import { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import { url_rest, api_key } from '../../api/cryptocompare';
import {AgGridColumn, AgGridReact, cellRendererFramework} from 'ag-grid-react';
import abbreviate from 'number-abbreviate';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import CoinChart from './CoinChart';
import TopChange from './CoinsTopChange';
import BottomChange from './CoinsBottomChange';
import USD from './USD';
import useStickyHeader from "./useStickyHeader";
import './Coins.scss';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const StickyText = ({ stickyRef }) => {
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    window.addEventListener("scroll", forceUpdate);
    return () => window.removeEventListener("scroll", forceUpdate);
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        zIndex: 10000,
        backgroundColor: stickyRef.current ? "green" : "red",
        color: "white"
      }}
    >
      {/* Is header sticky: {stickyRef.current.toString()} */}
    </div>
  );
};

const CoinList = () => {

  const [coins, setCoins] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [pageSize, setPageSize] = useState(100);
  const [rowData, setRowData] = useState([]);

  const [getTopCoins, setGetTopCoins] = useState([]);
  const [getBottomCoins, setGetBottomCoins] = useState([]);
  const [query, setQuery] = useState('USD');

  const stickyRef = useStickyHeader();

  

  // useEffect(() => {
  //   // axios.get(url_rest + '/all/coinlist?api_key=' + api_key)
  //   axios.get('https://min-api.cryptocompare.com/data/all/coinlist?api_key=193ab8d36ace770ec86eb59b5997326a5b81da049351bcc1fcd41cc031bda67c')
  //   .then(response => {
  //     console.log('COINS: ', response);
  //     Object.keys(response.data.Data).forEach(function(key) {
  //       getCoins.push(response.data.Data[key]);
  //     });

  //     setCoins(getCoins)
  //   })
  //   .catch(err => {
  //     console.log('Error: ', err)
  //   })
  // },[])

  const getCoins = [];

  useEffect(() => {
    // setGridApi(params.api);
    // setGridColumnApi(params.columnApi);

    // params.api.showLoadingOverlay();

    console.log('setQuery: ', query)

    axios.get('https://min-api.cryptocompare.com/data/top/totalvolfull?limit=100&tsym='+  query + '&api_key=ca0c75e0493e1c7b3016881cc0b6ca67f9195360661843f2ff3fa50675812b26')
    .then(response => {
      console.log('COINS: ', response);
      // Object.keys(response.data.Data).forEach(function(key) {
        setRowData(response.data.Data);
        getCoins.push(response.data.Data);
      // getCoins.sort((a, b) => parseInt(a.SortOrder) - parseInt(b.SortOrder)).map((sort) => {
      //   orderCoins.push(sort)
      // })
      // })
      // params.api.setRowData(getCoins);

      // console.log('getCoins: ', getCoins);
      // console.log('GET!!!! rowData: ', rowData);


    })
    .catch(err => {
      console.log('Error: ', err)
    })
  }, [query]);

  const setTopCoins = [];
  const setBottomCoins = [];
  const TopCoins = []

  useEffect(() => {

    
    
    // setTimeout(() => {
      
      rowData
      .sort(function(a, b){
        if("RAW" in a && "RAW" in b){
          console.log('QUERY: ', query);
            if(query === 'USD') {
            return b.RAW.USD.CHANGEPCT24HOUR - a.RAW.USD.CHANGEPCT24HOUR
            } else if (query === 'USDS') {
              return b.RAW.USDS.CHANGEPCT24HOUR - a.RAW.USDS.CHANGEPCT24HOUR
            }  else if (query === 'BTC') {
              return b.RAW.BTC.CHANGEPCT24HOUR - a.RAW.BTC.CHANGEPCT24HOUR
            }
        }
      })
      .slice(0, 4)
      .map((change) => {
        // if(change.USD) {
          if(query === 'USD') {
          return (
          setTopCoins.push(
            {
              FullName: change.CoinInfo.FullName,
              Name: change.CoinInfo.Name,
              Change: change.RAW.USD.CHANGEPCT24HOUR,
              Img: change.CoinInfo.ImageUrl,
              Price: change.RAW.USD.PRICE      
            }
          )
          )
        } else if (query === 'USDS') {
          return (
            setTopCoins.push(
              {
                FullName: change.CoinInfo.FullName,
                Name: change.CoinInfo.Name,
                Change: change.RAW.USDS.CHANGEPCT24HOUR,
                Img: change.CoinInfo.ImageUrl,
                Price: change.RAW.USDS.PRICE      
              }
            )
            )
        } else if (query === 'BTC') {
          return (
            setTopCoins.push(
              {
                FullName: change.CoinInfo.FullName,
                Name: change.CoinInfo.Name,
                Change: change.RAW.BTC.CHANGEPCT24HOUR,
                Img: change.CoinInfo.ImageUrl,
                Price: change.RAW.BTC.PRICE      
              }
            )
          )
        }
        // }
      })

      rowData
      .sort(function(a, b){
        if("RAW" in a && "RAW" in b){
          if(query === 'USD') {
          return a.RAW.USD.CHANGEPCT24HOUR - b.RAW.USD.CHANGEPCT24HOUR
          } else if (query === 'USDS') {
            return b.RAW.USDS.CHANGEPCT24HOUR - a.RAW.USDS.CHANGEPCT24HOUR
          }  else if (query === 'BTC') {
            return b.RAW.BTC.CHANGEPCT24HOUR - a.RAW.BTC.CHANGEPCT24HOUR
          }
      }
      })
      .slice(0, 4)
      .map((change) => {
        if(query === 'USD') {
          return (
          setBottomCoins.push(
            {
              FullName: change.CoinInfo.FullName,
              Name: change.CoinInfo.Name,
              Change: change.RAW.USD.CHANGEPCT24HOUR,
              Img: change.CoinInfo.ImageUrl,
              Price: change.RAW.USD.PRICE      
            }
          )
          )
        } else if (query === 'USDS') {
          return (
            setBottomCoins.push(
              {
                FullName: change.CoinInfo.FullName,
                Name: change.CoinInfo.Name,
                Change: change.RAW.USDS.CHANGEPCT24HOUR,
                Img: change.CoinInfo.ImageUrl,
                Price: change.RAW.USDS.PRICE      
              }
            )
            )
        } else if (query === 'BTC') {
          return (
            setBottomCoins.push(
              {
                FullName: change.CoinInfo.FullName,
                Name: change.CoinInfo.Name,
                Change: change.RAW.BTC.CHANGEPCT24HOUR,
                Img: change.CoinInfo.ImageUrl,
                Price: change.RAW.BTC.PRICE      
              }
            )
          )
        }
      })

    console.log('setTopCoins: ', setTopCoins)

    setTimeout(() => {setGetTopCoins(setTopCoins)}, 1000)
    setTimeout(() => {setGetBottomCoins(setBottomCoins)}, 1000)

    console.log('rowData: ', rowData)
      },[rowData, query])

    // }, 1000);

  console.log('getTopCoins222: ', getTopCoins)

  const ShowTopChange = () => {
    setTimeout(() => {
      return <TopChange top={getTopCoins} />
    }, 2000)

    return <TopChange top={getTopCoins} />
  }

  const ShowBottomChange = () => {
    setTimeout(() => {
      return <BottomChange top={getBottomCoins} />
    }, 2000)

    return <BottomChange top={getBottomCoins} />
  }

  // needs work still - going with column fikltering for now


  const QueryType = (qtype) => {
    setQuery(qtype);
    console.log(qtype)
  }

  return (
    <div className="container">
    <ShowTopChange />
    <ShowBottomChange />
    <div className="ag-theme-alpine mt25 mb100" style={{height: '200%', width: '100%', minHeight: '100vh', marginBottom: '100px'}}>
    
    
    
    <StickyText stickyRef={stickyRef} />

    <div className="GridMain">
    <Container>
      <Row className="GridHeader">
        {/* <Col>
          <span onClick={() => QueryType('fav')}>Favorites</span>
          <span onClick={() => QueryType('USD')}>USD Markets</span>
          <span onClick={() => QueryType('USDS')}>USDⓈ Markets</span>
          <span onClick={() => QueryType('BTC')}>BTC Markets</span>
        </Col> */}
        {/* <Col xs={6}>2 of 3 (wider)</Col> */}
        {/* <Col xs={3}>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control type="text" placeholder="Quick Filter" onChange={handleQuickFilter} size="sm" />
          </Form.Group>
        </Col> */}
      </Row>
    </Container>
    

    <USD />
    </div>
    </div>
    </div>
  )
}

export default CoinList;