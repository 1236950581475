import { useState }  from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { getDatabase, ref, set } from "firebase/database";
import Clock from '../Count/Clock';
// import PresaleLive from './PresaleLive'
// import PresaleConnect from './PresaleConnect';
import './index.scss';

const writeUserData = (name, email, password) => {
  const db = getDatabase();
  set(ref(db, 'users/'), {
    name: name,
    email: email,
    password : password
  });
}

const TokenPriceEth= 1531.35 / 1000000000;
const TokenPriceBnb= 204.7 / 1000000000;

export const PrivateSale = (props) => {

  const [deadline, setDeadline] = useState('December 1, 2023');

  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const labels = ['Soft Cap', 'Hard Cap'];

  // const data = {
  //     labels,
  //     data: [1.75, 4.75],
  //     borderColor: 'rgb(53, 162, 235)',
  //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
  // }

  const options = {
    indexAxis: 'y'
  }
  const dataHorBar = {
    labels: ['test'],
    datasets: [
      {
        label: 'My First dataset',
        backgroundColor: '#EC932F',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: [1750000,4750000]
      }
    ]
  };

  return (
    <section id="presale" className="presale">
      <div className="container">
        <AnimationOnScroll animateIn="animate__fadeInUp" delay={500}>
          <div className="row">
            <div className="col">
              <h2>Token Info</h2>
              <table class="table table-token">
                  <tbody>
                    <tr>
                      <td class="table-head">ICO Part 1 Starts</td>
                      <td class="table-des">Aug 01 2024 12am GMT</td>
                    </tr>
                    <tr>
                      <td class="table-head">ICO Part 2 Starts</td>
                      <td class="table-des">Sept 01 2024 12am GMT</td>
                    </tr>
                    <tr>
                      <td class="table-head">Total Token Supply</td>
                      <td class="table-des">1,000,000,000,000,000</td>
                    </tr>
                    <tr>
                      <td class="table-head">Pre-Sale Token Value</td>
                      <td class="table-des">1 BNB = 25,200,000 LLAMAS
                        <div >
                          1 USDT = 100,000 LLAMAS
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="table-head">Accepted</td>
                      <td class="table-des">BNB and USDT</td>
                    </tr>
                  </tbody>
                </table>
            </div>
            <div className="col">
              <h2>Pre-Sale ICO</h2>
              <div className="row">
                <div className="col">
                  <h5>The Llama ICO starts on Aug 01 @ 6am EST.</h5>
                  <h5>Go to Presale <a href="https://presale.lla.ma">Here</a></h5>
                  {/* <Clock deadline={deadline} /> */}
                </div>
              </div>
              {/* <div className="row mt25">
                <div className="col">
                  <h5>
                    Raised so far <span>$5000</span>
                  </h5>
                  <div className="progressBar">
                    <div
                      className="progress-percent bg-grad"
                      data-percent="30"
                      style={{ width: "30%" }}
                    ></div>
                    <div className="progress-point progress-point-1">
                      Soft Cap <span>$250k</span>
                    </div>
                    <div className="progress-point progress-point-2">
                      Hard Cap <span>$1m</span>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div className="col mt25">
                  <h3 className="h3">Register now and get <a href="/register" className="btn btn-success"><span>100</span> free Llama Tokens<i className="fa-solid fa-angle-right ml10"></i></a></h3>
                </div>
              </div>
              <div className="row mt20">
              
                <div className="col-12 bonus-tokens">
                <h3 className="h3">Purchase tokens and get more free tokens!</h3>
                  <div className="bonus bg-theme">
                  
                    <div className="bonus-info">
                    <span className="bonus-badge badge-xs">Now</span>
                      <div className="bonus-percent">
                        25% <small>Bonus</small>
                      </div>
                      <div className="bonus-date">End at Aug 31, 2024</div>
                      
                    </div>
                    <div className="bonus-info">
                    <span className="bonus-badge badge-xs grey">Aug 1 2024</span>
                      <div className="bonus-percent">
                        10%<small>Bonus</small>
                      </div>
                      <div className="bonus-date">Start at Sept 1, 2024</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <PresaleLive /> */}

            {/* <PresaleConnect /> */}

            {/* <p>
                Token contract address <a id="contractaddress" href="https://bscscan.com/address/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82">0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82</a>
              </p>
              <p>
                Official email address thetoken@gmail.com
              </p> 
              <a className="btn btn-custom btn-lg" href="https://pancakeswap.finance/swap?outputCurrency=0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82">Buy</a>
              <a className="btn btn-custom btn-lg btn-chart" href="https://poocoin.app/tokens/0x0ed7e52944161450477ee417de9cd3a859b14fd0">Chart</a>
              */}
          </div>
          {/* <div className="col">
              <div className="ContactLogo"><div><img src={contactlogo} /></div></div>
            <h1><span className="token"></span></h1>
              </div> */}

          <div className="row">
            <div className="col next-section">
            <a className="btn btn-primary" href="#about">About <i className="fa-solid fa-angle-right ml10"></i></a>
              {/* <h5>
                <div style={{ marginBottom: "20px" }}>
                  When the initial coin offering (ICO) for Llama is open,{" "}
                  <div style={{ marginBottom: "10px" }} />
                  all users can participate in the sale and purchase of the
                  first Llama token.
                </div>
              </h5> */}
              {/* <h4>The ICO will provide users with an opportunity to:</h4> */}
            </div>
          </div>
        </AnimationOnScroll>
      </div>

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Register</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Form>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Name" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPasswordConfirm">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control type="password" placeholder="Password Confirm" />
          </Form.Group>
        </Form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" onClick={writeUserData}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal> */}

    </section>
  );
}