import { useRef, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import './Charts.scss'


ChartJS.register(ArcElement, Tooltip, Legend);

export const IcoChart = () => {

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';

      const table = document.createElement('table');
      table.style.margin = '0px';

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map(b => b.lines);

      const tableHead = document.createElement('thead');

      titleLines.forEach(title => {
        const tr = document.createElement('tr');
        tr.style.borderWidth = 0;

        const th = document.createElement('th');
        th.style.borderWidth = 0;
        const text = document.createTextNode(title);

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      const tableBody = document.createElement('tbody');
      bodyLines.forEach((body, i) => {
        const colors = tooltip.labelColors[i];

        const span = document.createElement('span');
        span.style.background = colors.backgroundColor;
        span.style.borderColor = colors.borderColor;
        span.style.borderWidth = '2px';
        span.style.marginRight = '10px';
        span.style.height = '10px';
        span.style.width = '10px';
        span.style.display = 'inline-block';

        const tr = document.createElement('tr');
        tr.style.backgroundColor = 'inherit';
        tr.style.borderWidth = 0;

        const td = document.createElement('td');
        td.style.borderWidth = 0;

        const text = document.createTextNode(body);

        td.appendChild(span);
        td.appendChild(text);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.fontSize = '.8rem';
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  };

  const chartRef = useRef();

  const options = {
    maintainAspectRatio: false,
    // responsive: true,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: 'rgb(255, 99, 132)'
        }
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler
      }
    },
    layout: {
      padding: 20
    },
    // legend: {
    //   display: false,
    //   // position: "right"
    // },
    // legendCallback: function(chart) {
    //   var ul = document.createElement("ul");
    //   chart.data.datasets.forEach(function(dataset, datasetIndex) {
    //     let backgroundColor = dataset.backgroundColor;
    //     dataset.labels.forEach(function(label, labelIndex) {
    //       ul.innerHTML += `
    //               <li>
    //                  <span style="background-color: ${
    //                    backgroundColor[labelIndex]
    //                  }"></span>
    //                   ${label}
    //                </li>
    //             `;
    //     });
    //   });
    //   return ul.outerHTML;
    // },
    elements: {
      arc: {
        borderWidth: 10
      }
    },
    // tooltips: {
    //   callbacks: {
    //     label: function(tooltipItem, data) {
    //       var dataset = data.datasets[tooltipItem.datasetIndex];
    //       var index = tooltipItem.index;
    //       return dataset.labels[index] + ": " + dataset.data[index];
    //     }
    //   }
    // }
  };

  // const options = {
  //   plugins: {
  //       legend: {
  //           display: false,
  //           labels: {
  //               color: 'rgb(255, 99, 132)'
  //           }
  //       }
  //   },
  //   legend: {
  //     display: false,
  //     position: "right"
  //   },
  //   legendCallback: function(chart) {
  //     var ul = document.createElement("ul");
  //     chart.data.datasets.forEach(function(dataset, datasetIndex) {
  //       let backgroundColor = dataset.backgroundColor;
  //       dataset.labels.forEach(function(label, labelIndex) {
  //         ul.innerHTML += `
  //                 <li>
  //                    <span style="background-color: ${
  //                      backgroundColor[labelIndex]
  //                    }"></span>
  //                     ${label}
  //                  </li>
  //               `;
  //       });
  //     });
  //     return ul.outerHTML;
  //   },
  // }

  // legendCallback: (chart) => {
  //     console.log(dataset);

  //     const dataset = chart.data.datasets[0];
  //      const total = sumBy(dataset.data, number => parseInt(number, 10));

  //     return dataset.data.map((value, index) => {
  //         const outputValue = dataset.percentage ? valueAsPercentage(value, total) : value;
  //         return `<li>
  //             <em>${outputValue}</em>
  //         </li>`;
  //     }).join('');                    
  // }


  const onClick = (event) => {
    // console.log(event)
  }



  // var chartAccidentsByRoadConditionsClasses =  ["Dry","Not Available", "Wet", "Icy"];
  // var chartAccidentsByRoadConditionsLabels =  ["Dry","Not Available", "Wet", "Icy"];
  // var chartAccidentsByRoadConditionsData =  [31, 3, 3, 1];

  // var dataAccidentsByRoadConditions = {
  // 	labels: chartAccidentsByRoadConditionsLabels,
  // 	datasets: [{
  // 		data: chartAccidentsByRoadConditionsData,
  // 		backgroundColor: [ "#82a8c3","#b24339","#053454","#77954b" ],
  // 		hoverBackgroundColor: [ "#7597AF","#A03C33","#042E4B","#6B8643" ]
  // 	}]
  // };

  // $(document).ready(function() {
  // 	var canvasAccidentsByRoadConditions = document.getElementById("chart-AccidentsByRoadConditions").getContext("2d");
  // 	var chartAccidentsByRoadConditions = new Chart(canvasAccidentsByRoadConditions, {
  // 		type: 'pie',
  // 		data: dataAccidentsByRoadConditions,
  // 		options: {	
  // 			tooltips: {
  // 				enabled: false
  // 			},
  // 			legend: {						
  // 				display:false,
  // 			},
  //       legendCallback: function(chart) {	
  // 				var text = [];
  // 				text.push('<ul>');
  // 				for (var i=0; i<chart.data.datasets[0].data.length; i++) {
  // 					text.push('<li>');
  // 					text.push('<div class="legendValue"><span style="background-color:' + chart.data.datasets[0].backgroundColor[i] + '">');
  // 					text.push(chart.data.datasets[0].data[i] + '</span></div>');
  // 					text.push('<div class="legendLabel">');
  // 					if (chart.data.labels[i]) { text.push('<p class="label">' + chart.data.labels[i] + '</p>'); }
  // 					if (chart.data.datasets[0].data[i]) { 
  // 						text.push('<p class="percentage">' + chartValueToPercentage(chart.data.datasets[0].data[i],chartAccidentsByRoadConditions.getDatasetMeta(0).total) + '</p>'); 
  // 					}
  // 					text.push('</li>');
  // 				}
  // 				text.push('</ul>');
  // 				return text.join("");
  // 			}
  // 		}
  // 	});

  // 	// Create our legend
  // 	$('#legend-AccidentsByRoadConditions').prepend(chartAccidentsByRoadConditions.generateLegend());

  // 	// Bind our "Break-Out" Chart function
  // 	$('#chart-AccidentsByRoadConditions').on('mousemove mouseout',function(e){
  // 		var activeSegment = chartAccidentsByRoadConditions.getElementAtEvent(e);
  // 		pieChartHoverBreakout(this,activeSegment,e);
  // 	});

  // 	// Tie the legend to the chart tooltips				
  // 	Chart.helpers.each(document.getElementById('legend-AccidentsByRoadConditions').firstChild.childNodes, function(legendNode, index) {
  // 		Chart.helpers.addEvent(legendNode, 'mousemove', function() {
  //       highlightActiveSegment(chartAccidentsByRoadConditions, index, true);
  // 		});
  // 		Chart.helpers.addEvent(legendNode, 'mouseleave', function() {
  //       highlightActiveSegment(chartAccidentsByRoadConditions, index, false);
  // 		});
  // 	});					
  // });

  // function chartValueToPercentage(value,total) {
  // 	return Math.floor(((value/total)*100)+0.5) + '%';
  // }

  // // Function breakout the active "legend item" PieCharts
  // currentBreakoutIndex = null;
  // function pieChartHoverBreakout(oChart, activeSegment, eventType) {		
  // 	try {	
  // 		// First, remove any existing classes with "breakout" from the legend
  // 		var legend = ($(oChart).parent('.chartContainer').find('.legend'));	
  // 		var segmentIndex = (activeSegment.length && (typeof activeSegment[0]._index != 'undefined' && activeSegment[0]._index !== null)) ? activeSegment[0]._index : -1;
  // 		var breakout = (eventType.type === 'mousemove') ? true : false;
  // 		if (currentBreakoutIndex != segmentIndex) {
  // 			$.each(legend.find('li'), function(index,value) {
  // 				$(this).removeClass('breakout');
  // 			});
  // 			// Second, if we have a valid segment index and breakout is true
  // 			// we add the breakout class to the corresponding li in the legend
  // 			if (breakout && segmentIndex >= 0) {
  // 				currentBreakoutIndex = segmentIndex;
  // 				var targetSegment = legend.find('li').get(segmentIndex);//
  // 				$(targetSegment).addClass('breakout');			
  // 			} else {
  // 				currentBreakoutIndex = null;
  // 			}
  // 		}
  // 	} catch(e) {
  // 		// Nothing - just prevent errors in console
  // 		console.log(e);
  // 	}
  // }

  // function highlightActiveSegment(oChart, segmentIndex, highlight) {
  //   var activeSegment = oChart.data.datasets[0]._meta[0].data[segmentIndex];
  //   oChart.updateHoverStyle([activeSegment], null, highlight);
  //   oChart.render();
  // }

  const data = {
    labels: ['Team & Founders', 'Private Sale', 'Presale', 'Marketing and PR', 'Public Sale'],
    datasets: [
      {
        // label: '# of Votes',
        data: [25, 5, 10, 15, 45],
        backgroundColor: 'rgba(8, 49, 92, 1)',
        hoverBackgroundColor: 'rgba(255,255,255,.5)',
        hoverOffset: 20,
        borderColor: 'rgba(255,255,255, .5)',
        borderWidth: 1,
      },
    ],
  }



  // useEffect(() => {
  //   document.getElementById(
  //     "legend"
  //   ).innerHTML = chartRef.current.chartInstance.generateLegend();

  //   // document.querySelectorAll("#legend li").forEach((item, index) => {
  //   //   item.addEventListener("click", e => handleClick(e, index));
  //   // });
  // }, []);

  return (
    <section id="tokens" className="icoChart">
      <div className="container">
        <div className="section-title">
          <h2>
            Tokens <small>Breakdown of our Token Recipients.</small>
          </h2>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {/* <div class="col-xl-12 order-last text-left">
            <div class="token-info-s2">
                <div class="row gutter-vr-50px">
                    <div class="col-6 col-xxl-6 col-lg-6">
                        <div class="token-info-item color-1 animated" data-animate="fadeInUp" data-delay="0.7">
                            <div class="token-info-title">Start Time</div>
                            <h4 class="token-info-des">15 Sep, 2022</h4>
                        </div>
                    </div>
                    <div class="col-6 col-xxl-6 col-lg-6">
                        <div class="token-info-item color-1 animated" data-animate="fadeInUp" data-delay="0.7">
                            <div class="token-info-title">Token Symbol</div>
                            <h4 class="token-info-des">ICOX</h4>
                        </div>
                    </div>
                    <div class="col-6 col-xxl-6 col-lg-6">
                        <div class="token-info-item color-2 animated" data-animate="fadeInUp" data-delay="0.75">
                            <div class="token-info-title">End Time</div>
                            <h4 class="token-info-des">30 Nov, 2022</h4>
                        </div>
                    </div>
                    <div class="col-6 col-xxl-6 col-lg-6">
                        <div class="token-info-item color-2 animated" data-animate="fadeInUp" data-delay="0.75">
                            <div class="token-info-title">Tokens Offered</div>
                            <h4 class="token-info-des">15.0 M</h4>
                        </div>
                    </div>
                    <div class="col-6 col-xxl-6 col-lg-6">
                        <div class="token-info-item color-3 animated" data-animate="fadeInUp" data-delay="0.8">
                            <div class="token-info-title">Soft Cap</div>
                            <h4 class="token-info-des">1.5 K</h4>
                        </div>
                    </div>
                    <div class="col-6 col-xxl-6 col-lg-6">
                        <div class="token-info-item color-3 animated" data-animate="fadeInUp" data-delay="0.8">
                            <div class="token-info-title">Soft Cap</div>
                            <h4 class="token-info-des">1.5 K</h4>
                        </div>
                    </div>
                    <div class="col-6 col-xxl-6 col-lg-6">
                        <div class="token-info-item color-4 animated" data-animate="fadeInUp" data-delay="0.85">
                            <div class="token-info-title">Crowdsale</div>
                            <h4 class="token-info-des">10.5 M</h4>
                        </div>
                    </div>
                    <div class="col-6 col-xxl-6 col-lg-6">
                        <div class="token-info-item color-4 animated" data-animate="fadeInUp" data-delay="0.85">
                            <div class="token-info-title">Crowdsale</div>
                            <h4 class="token-info-des">10.5 M</h4>
                        </div>
                    </div>
                    <div class="col-6 col-xxl-6 col-lg-6">
                        <div class="token-info-item color-5 animated" data-animate="fadeInUp" data-delay="0.9">
                            <div class="token-info-title">Hard Cap</div>
                            <h4 class="token-info-des">12.0 M</h4>
                        </div>
                    </div>
                    <div class="col-6 col-xxl-6 col-lg-6">
                        <div class="token-info-item color-5 animated" data-animate="fadeInUp" data-delay="0.9">
                            <div class="token-info-title">Hard Cap</div>
                            <h4 class="token-info-des">12.0 M</h4>
                        </div>
                    </div>
                </div>
            </div>
          </div> */}
            <AnimationOnScroll animateIn="animate__fadeInUp" delay={500}>
              <table className="table mt100">
                <tbody>
                  <tr>
                    <th scope="row">Team & Founders</th>
                    <td>25%</td>
                    <th scope="row" style={{ borderBottom: "none" }}></th>
                    <th scope="row">Private sale</th>
                    <td>5%</td>
                  </tr>
                  <tr>
                    <th scope="row">Presale/ICO</th>
                    <td>10%</td>
                    <th scope="row" style={{ borderBottom: "none" }}></th>
                    <th scope="row">Marketing and PR</th>
                    <td>15%</td>
                  </tr>
                  <tr>
                    <th scope="row">Public Sale</th>
                    <td>45%</td>
                    <th
                      scope="row"
                      style={{ borderBottom: "none", padding: "0 25px" }}
                    ></th>
                    {/* <th scope="row">Title</th>
      <td>Mark</td> */}
                  </tr>
                </tbody>
              </table>
            </AnimationOnScroll>
          </div>
          <div className="col-xs-12 col-md-4">
            <AnimationOnScroll animateIn="animate__fadeInUp" delay={1000} style={{ height: '350px' }}>
              <Pie
                ref={chartRef}
                options={options}
                data={data}
                onClick={onClick}
                redraw={true}
              />
              <div className="row">
                <div className="col token-btn mt35">
                  <a className="btn btn-primary" href="#roadmap">Roadmap<i className="fa-solid fa-angle-right ml10"></i></a>
                </div>
              </div>
            </AnimationOnScroll>
          </div>
        </div>
      </div>
    </section>
  );
}
