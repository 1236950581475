import { AnimationOnScroll } from 'react-animation-on-scroll';
import teamPic from '../../../../assets/tonyanglesey.jpeg';
import './Team.scss';

export const Team = (props) => {

  return (
    <section id="team" className="team">
      <div className="container">
        <AnimationOnScroll animateIn="animate__fadeInUp" delay={500} style={{ minHeight: '350px' }}>
          <div className="row">
            <div className="col-xs-12">
              <div className='section-title text-center'>
                <h2>Meet the Team</h2>
                <p>
                  Meet the team - interested in geting involved? <a href="https://twitter.com/token_llama" target="_blank">Contact us</a>
                </p>
              </div>

              <div className='row text-center'>
                {props.data
                  ? props.data.map((d, i) => (
                    <div key={`${d.name}-${i}`} className='col col-sm-3 mb25' style={{ margin: '0 auto' }}>
                      <div className='card text-center'>
                        <img src={teamPic} alt='...' className='card-img-top' />
                        <div className='card-body'>
                          <h4>{d.name}</h4>
                          <p>{d.job}</p>
                        </div>
                      </div>
                    </div>
                  ))
                  : 'loading'}


                <div className="col-xs-12 mt35">
                  <a className="btn btn-primary" href="#contact">Contact <i className="fa-solid fa-angle-right ml10"></i></a>
                </div>


              </div>

            </div>

          </div>

        </AnimationOnScroll>
      </div>
    </section>
  )
}