import CoinList from '../Coins/CoinListGrid';
import Coin from '../Coins/Coin';
import Login from '../Account/Login';
import Logout from '../Account/Logout';
import Register from '../Account/Register';
import Reset from '../Account/Reset';
import Dashboard from '../Account/Dashboard';
import WhitePaper from '../ICO/components/WhitePaper';
import ICO from '../ICO/';
import Developers  from '../Developers'
import SocialTop10 from '../Coins/CoinListTop10';
import Blog from '../Blog/index';
import Post from '../Blog/post';


let arrayid = window.location.pathname;
const queryid = arrayid.split("/");
let id = queryid[2];
const title = queryid[3];
// let titleClean = title.replace("-", " ");

// if(queryid[3]) {
// let titleClean = title.replace(/-/g, ' ').replace(/^./, function(x){return x.toUpperCase()})
// } else {
//   let titleClean = title
// }

console.log('queryid: ', id)


export default [
  { path: '/', name: 'LLAMA Token', Component: ICO},
  { path: '/crypto-markets', name: 'Crypto Markets', Component: CoinList },
  { path: '/crypto-markets/:id', name: id, Component: Coin },
  { path: '/login', name: 'Login', Component: Login },
  { path: '/logout', name: 'Login', Component: Logout },
  { path: '/register', name: 'Register', Component: Register },
  // { path: '/reset', name: 'Reset', Component: Reset },
  // { path: '/whitepaper', name: 'White Paper', Component: WhitePaper },
  { path: '/dashboard', name: 'Dashboard', Component: Dashboard },
  { path: '/top10', name: 'Llama Top 10', Component: SocialTop10 },
  { path: '/developers', name: 'Developers', Component: Developers },
  { path: '/blog', name: 'Blog', Component: Blog },
  { path: '/blog/:id/:title', name: title, Component: Post }
]