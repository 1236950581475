
const Disclaimer = () => {
    return (
        <div id="disclaimer" className="container">
            <div className="row">
                <div className="col-xs-12 pt35 pb25">
                    <p>
                        <span className="bold mr10">Disclaimer:</span>The content on this website is for informational purposes only and does not constitute financial, investment, or other professional advice. Investing in cryptocurrencies carries inherent risks, and you should perform your own research before making any decisions. LlamaToken and its team members are not responsible for any losses or damages that may occur as a result of using the information provided on this website.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Disclaimer;