import contactlogo from '../../../assets/llama_green_500.png';

export const Social = () => {

  return (
    <div className="row pt20" style={{width: '100%'}}>
      <div className="col social text-center">
      <ul>
        {/* <li>
          <a href=''>
            <i className='fab fa-facebook-f'></i>
          </a>
        </li> */}
        <li>
          <a href='https://twitter.com/token_llama' target="_blank">
            <i className='fab fa-twitter'></i>
          </a>
        </li>
        <li>
          <a href='https://www.instagram.com/llama.token/' target="_blank">
            <i className='fab fa-instagram'></i>
          </a>
        </li>
        <li>
          <a href='https://www.reddit.com/r/LlamaCoin/' target="_blank">
            <i className='fab fa-reddit'></i>
          </a>
        </li>
        <li>
          <a href='https://discord.com/channels/987369470790086676/987369471247270000'>
            <i className='fab fa-discord'></i>
          </a>
        </li>
        <li className="footerLogo"><img src={contactlogo} /></li>
      </ul>
      </div>
    </div>
  )
}