import { useState, useEffect } from 'react'
import { Header } from './components/header'
import { Welcome } from './components/Welcome'
import { Roadmap } from './components/Roadmap/roadmap'
import { About } from './components/About/About'
import { Tokenomics } from './components/Tokenomics/Tokenomics'
import { Team } from './components/Team/Team'
import { Contact } from './components/Contact/Contact'
import { Purchase } from './components/Purchase/Purchase'
import { TokenSale } from './components/TokenSale/TokenSale'
import { IcoChart } from './components/Charts/Charts';
import Developers from '../Developers/index'

import { Ico } from  './components/Ico/Ico';
import { PrivateSale } from './components/Presale';

import JsonData from './data/data.json'
import SmoothScroll from 'smooth-scroll'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import './components/style.scss'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 100,
  speedAsDuration: true,
})

const ICO = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [deadline, setDeadline] = useState('Dec 1 2023');
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
    <div>
      
      <PrivateSale data={deadline} />
      <Welcome />
      {/* <Ico data={deadline} /> */}
      
      {/* <Header data={landingPageData.Header} /> */}
      <About data={landingPageData.Info} />
      {/* <Info data={landingPageData.Info} /> */}
      {/* <TokenSale /> */}
      {/* <Tokenomics data={landingPageData.Tokenomics} /> */}
      <IcoChart />
      {/* <Purchase /> */}
      <Roadmap data={landingPageData.Roadmap} />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />
      <Developers />

    </div>
  )
}

export default ICO;
