import { AnimationOnScroll } from 'react-animation-on-scroll';

export const Contact = () => {

  return (
    <section id="contact" className='container full-width contact'>
      <AnimationOnScroll animateIn="animate__fadeInUp" delay={500} style={{ minHeight: '350px' }}>
        <div className="row text-center">
          <div className="col-xs-12">
            <div className='section-title'>
              <h2>Contact LLAMA</h2>
            </div>
            <div className="col-xs-12 about-text">
              If you have any questions or would like to get in contact with us, please reach out to us at:
            </div>
            {/* <p>Email: llamacoinllama@gmail.com</p> */}

            {/* <p>Twitter: <a href="https://twitter.com/token_llama" target="_blank">https://twitter.com/token_llama</a></p> */}

            <p>Instagram: <a href="https://www.instagram.com/llama.token/" target="_blank">https://www.instagram.com/llama.token</a></p>

            {/* <p>Contract Address: <a href="https://bscscan.com/address/0xdff999878d59e02fd73baaf5a8e5b559b6e711e9" target="_blank">0xdFf999878D59E02fD73BAAf5a8E5B559B6E711e9</a></p> */}

          </div>
        </div>
        <div className="row">
          <div className="col token-btn mt35">
            <a className="btn btn-primary" href="#developers">Developers<i className="fa-solid fa-angle-right ml10"></i></a>
          </div>
        </div>

      </AnimationOnScroll>
    </section>
  )
}