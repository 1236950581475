import { useState, useEffect } from 'react';
import axios from 'axios';
import './index.scss';

const Post = ({match}) => {
const [postId, setPostId] = useState(match.params.id);
const [post, setPost] = useState();
const [featuredImage, setFeaturedimage] = useState();

console.log('PostID: ', match.params.id)

  // useEffect(() => {

    const getPost = async () => {

      await axios
      .get('https://fathomless-taste.000webhostapp.com/wp-json/wp/v2/posts/' + postId + '')
      .then(function(response) {
        console.log(response.data)
        setPost(response.data)
      })
      .catch(error => console.log(error))

    }

      console.log('Data: ', post);
      
      
      const getImage = async () => {
        post ?
        await axios
         .get(post._links["wp:featuredmedia"][0].href)
         .then((response) => {
          console.log('IMG: ', response);
          setFeaturedimage(response.data.source_url);
        })
        : <div className="container">...featured img</div>
      };
      
      useEffect(() => {
        getPost();
      }, []);
      useEffect(() => {
        getImage();
      }, post);

     
    
  // }, []);

  return(
    post ?
    <div className="container blog-post">
      
      <img src={featuredImage} />
      <h1>{post.title.rendered}</h1>
      <div
          className="card-text"
          dangerouslySetInnerHTML={{ __html: post.content.rendered }}
        />
    </div>
    : <div className="col text-center bold">...LOADING POST</div>
    )

  
}

export default Post;