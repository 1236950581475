import { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Line } from 'react-chartjs-2';

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
// import '@fortawesome/fontawesome-free/css/all.min.css';

// Line.register(LinearScale);

const CoinChart = (props) => {

  const [chartData, setChartData] = useState([])
  const [chartLabels, setChartLabels] = useState([])
  const [dates, setDates] = useState(23);

  const getDays = [];
  const setClose = [];
  const setTime = [];

  // const canvas = document.getElementById('coinchart');
  // const ctx = canvas.getContext('2d');

  // const gradient = ctx.createLinearGradient(0, 0, 0, 400);
  // gradient.addColorStop(0, 'rgba(250,174,50,1)');   
  // gradient.addColorStop(1, 'rgba(250,174,50,0)');

  const Loading = () => {
    console.log('Loading...')
    return (
    <i className="faSolid faSpinner"></i>
    )
  }


  useEffect(() => {
    // console.log('props: ', 'https://min-api.cryptocompare.com/data/v2/histoday?fsym=' + props.coin + '&tsym=USD&limit=6')
     axios.get('https://min-api.cryptocompare.com/data/v2/histohour?fsym=' + props.coin + '&tsym=USD&limit=' + dates + '&api_key=ca0c75e0493e1c7b3016881cc0b6ca67f9195360661843f2ff3fa50675812b26')
    //  .then((resp) => resp)
     .then((response) => {
      // console.log('DATA: ', response)
       response.data.Response != 'Error' ? 
       getDays.push(response.data.Data.Data) 
       : console.log(response.data.Message)
        // response.map((days) => {
        //   console.log('days: ', days.data.Data.Data)
        //   setDays.push(days.data.Data.Data.close)
        // })

        // setTimeout(() => {setChartData(setDays)}, 1000)

        // console.log('getDays: ', getDays)

        getDays[0].map((week) => {
        // for (const obj of getDays) {
          // console.log('week: ', week.close)
          setClose.push(week.close)
          setTime.push(dayjs(week.time * 1000).format('MMM DD'))
        })

        // console.log('setWeek: ', setWeek)
        setTimeout(() => {
          setChartData(setClose);
          setChartLabels(setTime)
          // Line.update()
        
        }, 500);
      })
      .catch((err) => {
        console.log('Error: ', err)
      })
      
  },[dates])

  // console.log('chartData:', chartData)

  // const ctx = Line.getContext("2d");
  // const gradient = ctx.createLinearGradient(0, 0, 0, 400);
  // gradient.addColorStop(0, 'rgba(250,174,50,1)');   
  // gradient.addColorStop(1, 'rgba(250,174,50,0)');


  const data = {
    labels: chartLabels,
    datasets: [
      {
        // label: '# of Votes',
        data: chartData,
        fill: false,
        pointRadius: 0,
        // backgroundColor: gradient,
        borderColor: 'rgba(27, 63, 229, 1)',
        borderWidth: 1
      },
    ],
  };
  
  const options = {
    animation: true,
    scales: {
      y: {
        beginAtZero: false,
        display: false,
        // grid: {
        //   color: 'white',
        // }
      },
      x: {
        display: false //this will remove all the x-axis grid lines
      }
    },
    plugins: {
      legend: {
          display: false,
          // labels: {
          //     color: 'rgb(255, 99, 132)'
          // }
      },
      tooltip: {enabled: false},
  }

  };

  return (
    <>
    {chartData.length > 0 ?
    <>
    <Line
      className='coinchart'
      data={data} 
      options={options} 
      height="70"
      style={{marginTop: '10px'}}
      />
      {/* <i className="fa-solid fa-user"></i> */}
      </>
      : <span className="loading">loading...</span>
    }
    </>
  )

}

export default CoinChart;