import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { url_rest, api_key } from '../../api/cryptocompare';
import { AgGridReact } from 'ag-grid-react';
import abbreviate from 'number-abbreviate';
import { Container, Row, Col, Form, Button, Overlay, Popover } from 'react-bootstrap';
import CoinChart from './CoinChart';

import './Coins.scss';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const SocialTop10 = (props) => {

  const [coins, setCoins] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [pageSize, setPageSize] = useState(100);
  const [rowData, setRowData] = useState([]);

  function numDec(n){return n%1==0?0:(""+n).length-(""+n).lastIndexOf(".")-1}

  // function numComma(num) {
  //   return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // }

  // const NumForm8 = (value) => {
  //   return value.toFixed(8).toLocaleString(
  //     "en-US",
  //     {
  //       useGrouping: true,
  //     }
  //   );
  // };
  // const NumForm = (value, decimal) => {
  //   return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString(
  //     "en-US",
  //     {
  //       useGrouping: true,
  //     }
  //   );
  // };

  // button popover
  // const [show, setShow] = useState(false);
  // const [target, setTarget] = useState(null);
  // const ref = useRef(null);

  // const handleClick = (event) => {
  //   setShow(show);
  //   // setTarget(event.target);
  //   // console.log('Event: ', event.target)
  //   console.log('Show: ', show)
  // };

  const [colDefs, setColDefs] = useState([
    
    // {
    //   field: 'SortOrder',
    //   headerName: '',
    //   // filter: "agNumberColumnFilter",
    //   // sortable: true,
    //   comparator: (valueA, valueB, nodeA, nodeB, isInverted) => valueA - valueB,
    //   sort: "asc",
    //   // flex: 1,
    //   width: 75,
    // },
    {
      field: 'CoinInfo.FullName',
      headerName: 'Name',
      // filter: "agTextColumnFilter",
      sortable: true,
      flex: 1,
    //   width: 300,
      suppressSizeToFit: true,
      getQuickFilterText: params => {
        return [params.value, params.data.CoinInfo.Name];
      },
      cellStyle: params => {
        return {marginTop: '8px'};
      },
      cellRendererFramework: function(params) {
        if(params.value) {
          return (
            <>
            <img src={`http://cryptocompare.com/${params.data.CoinInfo.ImageUrl}`} style={{width: '30px', marginRight: '10px', marginTop: '5px', float: 'left'}}/>
            <a href={`/crypto-markets/${params.data.CoinInfo.Name}`}>{params.value}</a> 
            <span className="ml4">({params.data.CoinInfo.Name})</span>
            </>
          )
        } else {
          return 'N/A'
        }
      }      
    },
    {
      field: 'RAW.USD.PRICE',
      headerName: 'Price',
      // filter: "agTextColumnFilter",
      sortable: true,
      flex: 1,
      suppressSizeToFit: true,
      getQuickFilterText: params => {
        return null;
      },
      cellStyle: params => {
        return {marginTop: '8px'};
      },
      cellRendererFramework: function(params) {
        // return Number.parseInt(params.value) + '%'
        if(params.value) {
          // console.log('decimalPlaces: ', params.value + ' + '+ numDec(params.value));
          if(numDec(params.value) > 8) {
            return '$' + params.value.toLocaleString('en-US', { minimumFractionDigits: 8})
          } else {
            return '$' + params.value.toLocaleString('en-US', { minimumFractionDigits: numDec(params.value) })
          }
        } else {
          return 'N/A'
        }
        return null
      },
    },
    {
      field: 'RAW.USD.CHANGEPCT24HOUR',
      headerName: 'Change (24 hrs)',
      // filter: "agNumberColumnFilter",
      width: 175,
      // flex: 1,
      sort: "desc",
      sortable: true,
      suppressSizeToFit: true,
      getQuickFilterText: params => {
        return null;
      },
      cellStyle: params => {
        if (params.value < 0) {
            //mark negative cells as red
            return {color: 'red', marginTop: '8px'};
        } else {
          return {color: 'green', marginTop: '8px'};
        }
        return null;
      },
      cellRendererFramework: function(params) {
        // return Number.parseInt(params.value) + '%'
        if(params.value) {
          return params.value.toFixed(4) + ' %'
        } else {
          return 'N/A'
        }
        return null
      },
    },
    {
      field: 'RAW.USD.TOTALTOPTIERVOLUME24HTO',
      headerName: 'Volume (24 hrs)',
      // filter: "agNumberColumnFilter",
      sortable: true,
      flex: 1,
      suppressSizeToFit: true,
      getQuickFilterText: params => {
        return null;
      },
      cellStyle: params => {
        return {marginTop: '8px', textTransform: 'uppercase'};
      },
      cellRendererFramework: function(params) {
        if(params.value) {
          return '$' + abbreviate(params.value, 2)
        } else {
          return 'N/A'
        }
      }
    },
    {
      field: 'RAW.USD.HIGH24HOUR',
      headerName: '24 hour high',
      // filter: "agTextColumnFilter",
      flex: 1,
      sortable: true,
      suppressSizeToFit: true,
      cellStyle: params => {
        return {marginTop: '8px', textTransform: 'uppercase'};
      },
      cellRendererFramework: function(params) {
        if(params.value) {
          if(numDec(params.value) > 8) {
            return '$' + params.value.toLocaleString('en-US', { minimumFractionDigits: 8})
          } else {
            return '$' + params.value.toLocaleString('en-US', { minimumFractionDigits: numDec(params.value) })
          }
        } else {
          return 'N/A'
        }
      }
    },
    {
      field: 'RAW.USD.LOW24HOUR',
      headerName: '24 hour low',
      // filter: "agTextColumnFilter",
      flex: 1,
      sortable: true,
      suppressSizeToFit: true,
      cellStyle: params => {
        return {marginTop: '8px', textTransform: 'uppercase'};
      },
      cellRendererFramework: function(params) {
        if(params.value) {
          if(numDec(params.value) > 8) {
            return '$' + params.value.toLocaleString('en-US', { minimumFractionDigits: 8})
          } else {
            return '$' + params.value.toLocaleString('en-US', { minimumFractionDigits: numDec(params.value) })
          }
          } else {
            return 'N/A'
          }
      }
    },
    {
      field: '',
      headerName: '24 hour chart',
      // filter: "agTextColumnFilter",
      flex: 1,
      suppressSizeToFit: true,
      cellRendererFramework: function(params) {
        return <CoinChart coin={params.data.CoinInfo.Name} />
      }
    },
    // {
    //   field: '',
    //   headerName: '',
    //   // filter: "agTextColumnFilter",
    //   flex: 1,
    //   suppressSizeToFit: true,
    //   cellStyle: {'text-align': 'center', marginTop: '8px'},
    //   cellRendererFramework: function(params) {
    //     return (
    //     //<a href='https://accounts.binance.us/en/register?ref=56682569' ><Button className="btn-xsm" variant="primary" size="sm">Purchase - Trade</Button></a>
    //     <div ref={ref}>
    //       <Button className="btn-xsm" variant="primary" size="sm" onClick={handleClick}>By/Sell</Button>
    //       {show ? (
    //         <h1>TEST</h1>
    //   //     <Overlay
    //   //       show={show}
    //   //       target={target}
    //   //       placement="bottom"
    //   //       container={ref}
    //   //       containerPadding={20}
    //   //     >
    //   //   <Popover id={params.data.CoinInfo.Name}>
    //   //     <Popover.Header as="h3">Popover bottom</Popover.Header>
    //   //     <Popover.Body>
    //   //       <strong>Holy guacamole!</strong> Check this info.
    //   //     </Popover.Body>
    //   //   </Popover>
    //   // </Overlay>
    //       )
    //     : null}
    // </div>
    //     )
    //   }
    // },
  ])

  // const stickyRef = StickyHeader();

  

  // useEffect(() => {
  //   // axios.get(url_rest + '/all/coinlist?api_key=' + api_key)
  //   axios.get('https://min-api.cryptocompare.com/data/all/coinlist?api_key=06c7d191b8cb7523c80f50653cbc5ff172c88f3d1d74959d21ebcf1ef605ab33')
  //   .then(response => {
  //     console.log('COINS: ', response);
  //     Object.keys(response.data.Data).forEach(function(key) {
  //       getCoins.push(response.data.Data[key]);
  //     });

  //     setCoins(getCoins)
  //   })
  //   .catch(err => {
  //     console.log('Error: ', err)
  //   })
  // },[])

  const getCoins = [];

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    params.api.showLoadingOverlay();

    axios.get('https://min-api.cryptocompare.com/data/top/totalvolfull?limit=100&tsym=USD&api_key=ca0c75e0493e1c7b3016881cc0b6ca67f9195360661843f2ff3fa50675812b26')
    .then(response => {
      console.log('COINS: ', response);
      // Object.keys(response.data.Data).forEach(function(key) {
        setRowData(response.data.Data);
        getCoins.push(response.data.Data)
      // getCoins.sort((a, b) => parseInt(a.SortOrder) - parseInt(b.SortOrder)).map((sort) => {
      //   orderCoins.push(sort)
      // })
      // })
      // params.api.setRowData(getCoins);

      console.log('getCoins: ', getCoins)


    })
    .catch(err => {
      console.log('Error: ', err)
    })
  };

  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };
  const handleQuickFilter = event => {
    gridApi.setQuickFilter(event.target.value);
    // setFilter(event.target.value)

  };

  const noRowsTemplate = 'No matching data';


  return (
    <>
     
    {/* <Col md={{ span: 3 }} style={{position: 'relative', top: '-50px', marginLeft: '15px'}}>
      <Form.Group className="mb-3 mr15" controlId="QuickFilter">
        <Form.Control type="text" placeholder="Quick Filter" onChange={handleQuickFilter} size="sm" />
      </Form.Group>
    </Col> */}
    <AgGridReact
      domLayout='autoHeight'
      className='coinList top10'
      defaultColDef={{ resizable: false, floatingFilter: false }}
      onGridReady={onGridReady}
      onFirstDataRendered={onFirstDataRendered}
      // animateRows
      pagination={true}
      paginationPageSize='10'
      columnDefs={colDefs}
      rowData={rowData}
      overlayLoadingTemplate="loadingTemplate"
      overlayNoRowsTemplate={noRowsTemplate}
    />
    </>
  )
}

export default SocialTop10;