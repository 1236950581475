import React from "react";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ crumbs }) => {
  // Don't render a single breadcrumb.
  // console.log('crumbs: ', crumbs)
  if (crumbs.length <= 1) {
    return null;
  }

  return (
    <div className="container mt20 mb-4 bg-gray-300 breadcrumbs">
      {/* Link back to any previous steps of the breadcrumb. */}
      {crumbs.map(({ name, path }, key) =>
        key + 1 === crumbs.length ? (
          
          <>
          <span key={key}>
            {name}
          </span>
          </>
        ) : (
          <>
          <Link className="text-blue-500 mr5" to={path}>
            {name}
          </Link>
          <span key={key} style={{color: '#000000'}} className="mr5">&#8729;</span>
          </>
        )
      )}
    </div>
  );
};

export default Breadcrumbs;
