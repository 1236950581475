import { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';


const News = (props) => {

  const [news, setNews] = useState([])

  useEffect(() => {

    axios.get('https://min-api.cryptocompare.com/data/v2/news/?lang=EN&categories=' + props.coin + '&excludeCategories=Sponsored')
      //  .then((resp) => resp)
      .then((response) => {
        console.log('NEWS: ', response)
        response.data.Response != 'Error' ?

          setNews(response.data.Data)
          : console.log(response.data.Message)

      })


  }, [])


  return (
    <div id="news" className="row mt35 ">
      <h2>News - {props.coin} </h2>

      {news.map((news) => {
        let categories = news.categories.split('\|', -1);
        let tags = news.tags.split('\|', -1);
        console.log('Cats: ', categories);
        return (
          <div className="col-md-3 col-sm-12 mb20">
            <div className="card">
              <img src={news.imageurl} className="card-img-top" />
              <div className="card-body" style={{ marginBottom: '20px' }}>
                <h5 className="card-title">{news.title}</h5>
                <small className="mb30">{dayjs(news.published_on * 1000).format('MMM DD YYYY')}</small>
                <div className="card-text">
                  {/* {news.body.substring(0,100)} */}
                  <p>{
                    categories.map((cats) => {
                      return <span className="cats">{cats} </span>
                    })
                    }</p>
                  <p>{
                    tags.map((tags) => {
                      return <span className="tags">{tags} </span>
                    })
                    }</p>
                  <p><a href={news.url} target="_blank" className="btn btn-primary btn-sm mt20">Read More</a></p>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default News;