import { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from '../Layout/Header/Header';
import Footer from '../Layout/Footer/Footer';
import Breadcrumbs from '../Layout/Breadcrumbs';
import Routes from '../Layout/Routes'
import ScrollToTop from '../../utils/ScrollToTop';
import { Helmet } from "react-helmet";
import './App.scss';

function App() {

  // useEffect(() => {
  //   document.title = 'Llama Coin: Elevate Your Crypto Experience with Zen Confidence and Power.';
  // }, []);
  return (
    <>
      <Router>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Llama Coin: Elevate Your Crypto Experience with Zen Confidence and Power.</title>
          <meta name="description" content="Discover a new era of cryptocurrency with Llama Coin. Elevate your crypto experience to unprecedented heights, embracing the serene confidence and powerful innovation that sets Llama Coin apart. Join the revolution and redefine the way you engage with digital assets. Invest with Zen-like assurance and unlock the potential of Llama Coin in the ever-evolving world of crypto." />
          <meta name="keywords" content="Bitcoin, Blockchain Technology, Cryptocurrency Investments, Ethereum, Decentralized Finance (DeFi), Crypto Trading, Digital Assets, Altcoins, Cryptocurrency Market, Crypto Wallets" />
        </Helmet>
        <Header />
        <ScrollToTop />
        <Switch>
          {Routes.map(({ path, name, Component }, key) => (
            <Route
              exact
              path={path}
              key={key}
              render={props => {
                const crumbs = Routes
                  // Get all routes that contain the current one.
                  .filter(({ path }) => props.match.path.includes(path))
                  // Swap out any dynamic routes with their param values.
                  // E.g. "/pizza/:pizzaId" will become "/pizza/1"
                  .map(({ path, ...rest }) => ({
                    path: Object.keys(props.match.params).length
                      ? Object.keys(props.match.params).reduce(
                        (path, param) =>
                          path.replace(`:${param}`, props.match.params[param]),
                        path
                      )
                      : path,
                    ...rest
                  }));

                // console.log(`Generated crumbs for ${props.match.path}`);
                // crumbs.map(({ name, path }) => console.log({ name, path }));

                return (
                  <div className="container main-container">
                    <Breadcrumbs crumbs={crumbs} />
                    <Component {...props} />
                  </div>
                );
              }}
            />
          ))}
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
