import { useState, useEffect } from 'react';
import { Nav, Navbar, NavDropdown, Container, DropdownButton, Dropdown, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../../../assets/llama_green.png'
import './Header.scss'

const Header = () => {

  const [colorChange, setColorchange] = useState(false);
  const [colorChange1, setColorchange1] = useState(false);

  // const changeNavBarColor1 = () => {
  //   if (window.scrollY === 5) {

  //     setColorchange1(true);

  //   } else {
  //     setColorchange1(false);
  //   }
  // }
  const changeNavbarColor = () => {
    if (window.scrollY >= 100) {
      setColorchange(true);
    }
    else {
      setColorchange(false);
      // setColorchange1(false);
    }
  };
  window.addEventListener('scroll', changeNavbarColor);

  const userName = sessionStorage.getItem('name') ? sessionStorage.getItem('name') : 'Logged In';

  return (
    <>
      <Navbar
        collapseOnSelect
        className={colorChange ? ' mainNav stick' : 'mainNav mb35'}
        bg={colorChange ? 'dark' : 'dark'}
        variant={colorChange ? 'dark' : 'dark'}
        expand="lg"
      >
        <Container>
          <Navbar.Brand href="#home"><Link to="/"><img src={logo} className="logo" /></Link></Navbar.Brand>
          <Nav.Link className="mr35 bold logo-text" href="/">LLAMA</Nav.Link >
          <Navbar.Toggle aria-controls="navbar-collapse" id="menuToggler" />
          <Navbar.Collapse id="mainMenu">
            <Nav className="me-auto">
              <Nav.Link href="/#presale">Token</Nav.Link>
              {/* <Nav.Link href="/#ico">ICO</Nav.Link> */}
              <Nav.Link href="/#intro">Intro</Nav.Link>
              {/* <Nav.Link href="/#presale" >ICO</Nav.Link>*/}
              <Nav.Link href="https://presale.lla.ma">PreSale</Nav.Link>
              {/* <Nav.Link href="/#about" >About</Nav.Link>  */}
              <Nav.Link href="/#trade">Purchase/Trade</Nav.Link>  
              <Nav.Link href="/#roadmap" >Roadmap</Nav.Link>
              <Nav.Link href="/#tokenomics">Tokenomics</Nav.Link>
              {/* {/* <Nav.Link href="/#team" >Team</Nav.Link> */}
              <Nav.Link href="/#contact" >Contact</Nav.Link>
              <Nav.Link href="/blog" >Blog</Nav.Link>
              <Nav.Link href="/#developers" >Developers</Nav.Link>
              <span className="ml10 mr10 mt7 bold" style={{ color: 'white' }}>&#x2026;</span>
              <Nav.Link href="/crypto-markets" >Crypto Markets</Nav.Link>
            </Nav>
            {sessionStorage.getItem('name') ?
              <NavDropdown
                title={sessionStorage.getItem('name')}
                id="basic-nav-dropdown" className="justify-content-end nav-login">
                <NavDropdown.Item href="/dashboard">Dashboard</NavDropdown.Item>
                <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
              </NavDropdown>
              : <Nav.Link href="/login" className="btn btn-sm btn-primary">Login</Nav.Link>
            }
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Header;