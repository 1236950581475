import { AnimationOnScroll } from 'react-animation-on-scroll';
import ImgCommmunity from '../../../../assets/images/image-about-community.jpg';
import ImgCurrentState from '../../../../assets/images/image-about-currentstate.jpg'

export const About = (props) => {
  return (
    <section id="about" className="section">
      <div className="container">
        <div className="section-title">
          <h2>About LLAMA</h2>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
            {/* <AnimationOnScroll animateIn="animate__fadeInUp" delay={500} style={{marginBottom: '50px'}}>
             <h3></h3>
            </AnimationOnScroll> */}
              
              {/* <h3>LLAMA tokens are for tipping, sharing and exchanging crypto, and making payments.</h3> */}

              {/* <h3>LLAMA tokens are for tipping, sharing and exchanging crypto, and making payments.</h3>
              <h4>We are excited to announce the upcoming initial token offering (ICO) of our new cryptocurrency token, [token Name]. This cutting-edge digital asset is designed to revolutionize the way we make transactions and store value. With its advanced technology, [token Name] offers fast, secure and low-cost transfers while also providing holders with unique benefits and opportunities. The ICO will take place on [Date] and will offer a limited supply of [token Name] tokens to early adopters and supporters. Join us in this exciting new venture and be a part of the future of finance.</h4>
              <h4>We are excited to announce the launch of Llama, a new cryptocurrency meme token that is set to disrupt the world of crypto. Llama is a unique digital asset that combines the power of blockchain technology with the fun and quirky world of memes. With its initial token offering (ICO), Llama is looking to bring a new level of excitement and engagement to the crypto community. This innovative new token is the perfect way for anyone who loves memes to get involved in the world of cryptocurrency, and we can't wait to see what Llama will do next. So whether you're a seasoned crypto investor or a newcomer to the world of digital assets, be sure to keep an eye on Llama and get ready for a wild ride!</h4>
              <h4>Llama is a new cryptocurrency meme token that is all set to take the world by storm. This unique and innovative token is built with a primary focus on providing a fun and lighthearted experience to its users. It is inspired by the meme culture and aims to offer a more casual and playful approach to the traditional cryptocurrency world. The Llama team has put in extensive effort to create a user-friendly platform that is easy to navigate, even for those who are new to the cryptocurrency space.</h4>} */}

              <AnimationOnScroll animateIn="animate__fadeInUp" delay={1000}>
                <div className="row">
                <div className="col-md-6 col-xs-12">
                  <div className="card">
                  <img src={ImgCommmunity} class="card-img-top" alt="..." />
                  <div className="card-title"><h3>Background</h3></div>
                  <div className="card-body"><p>LLAMA tokens are for community building and tools for tipping, sharing and exchanging crypto and making payments.</p><p>Llama is a decentralized cryptocurrency that is built on the Binance blockchain.</p><p>It is designed to provide users with a fast, secure, and affordable way to transfer funds and make transactions. </p></div>
                  </div>
                </div>
                {/* <div className="col-md-4">
                <div className="card">
                  <div className="card-title"><h3>Token Distribution</h3></div>
                  <div className="card-body"><p>The total supply of Llama Tokens is 1,000,000,000,000 (One Trillion).</p><p>60% of the tokens will be sent to the liquidity pool.</p><p>20% will be held by the development team, with the remaining 20% will be used as tokens for future exchanges and liquidity pools.</p></div>
                
                </div>
                </div> */}
                <div className="col-md-6 col-xs-12">
                <div className="card">
                <img src={ImgCurrentState} class="card-img-top" alt="..." />
                  <div className="card-title"><h3>Current State</h3></div>
                  <div className="card-body"><p>The development of Llama Tokens is already underway and we have a clear roadmap for the future. Our main focus is on building a strong community and developing partnerships.</p><p>We will also be focusing on improving the token's features and keeping it user-friendly and easy to use.</p></div>
                </div>
                </div>
                </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__fadeInUp" delay={1000}>
                {/* <div className="row mt20">
                  <div className="col-md-4">
                    <div className="card card-body">
                    <i className="llama-font">1</i>
                      Get in early on this exciting new project and own a piece
                      of the Llama pie The Llama team believes that this token
                      will revolutionize the cryptocurrency world by offering
                      something truly unique and entertaining. With its unique
                      value proposition and its focus on user engagement, Llama
                      is poised to be one of the hottest new cryptocurrencies in
                      the market.
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card card-body">
                    <i className="llama-font">2</i>
                      The Llama team believes that this token will revolutionize
                      the cryptocurrency world by offering something truly
                      unique and entertaining
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card card-body">
                    <i className="llama-font">3</i>
                      With its unique value proposition and its focus on user
                      engagement, Llama is poised to be one of the hottest new
                      cryptocurrencies in the market
                    </div>
                  </div>
          </div> */}
          <div className="row about-btn">
            <div className="col">
            <a className="btn btn-primary" href="#trade">Purchase / Trade <i className="fa-solid fa-angle-right ml10"></i></a>
            </div>

          </div>
              </AnimationOnScroll>

              {/* Introduction

The cryptocurrency market is rapidly growing and evolving, with new tokens and tokens being introduced every day. The initial token offering (ICO) has become a popular way for projects to raise funds, and it has also become a way for investors to get in on the ground floor of a new project. In this whitepaper, we will introduce our new cryptocurrency token, XYZ token, and explain how it will revolutionize the industry.

Background

XYZ token is a decentralized cryptocurrency that is built on the Ethereum blockchain. It is designed to provide users with a fast, secure, and affordable way to transfer funds and make transactions. The token is based on a unique algorithm that is designed to be resistant to mining centralization, which is a common problem in the cryptocurrency industry.

The Problem

The current cryptocurrency market is plagued by several problems. For example, many tokens are subject to mining centralization, which means that a small group of miners control a large portion of the mining power. This can lead to a lack of decentralization and security issues. Additionally, many tokens are slow and expensive to transfer, making them less practical for everyday use.

The Solution

XYZ token addresses these problems by using a unique algorithm that is resistant to mining centralization. This means that mining power is distributed more evenly among users, which leads to a more decentralized and secure network. Additionally, XYZ token is designed to be fast and affordable to transfer, making it more practical for everyday use.

Token Distribution

The total supply of XYZ tokens is 100 million. 60% of the tokens will be available for purchase during the ICO, 20% will be held by the development team, and the remaining 20% will be used for marketing and community building.

Roadmap

The development of XYZ token is already underway and we have a clear roadmap for the future. Our main focus is on building a strong community and developing partnerships. We will also be focusing on improving the token's features and making it more user-friendly.

Conclusion

XYZ token is a revolutionary cryptocurrency that is designed to address the problems of mining centralization and high transfer fees. With its unique algorithm and focus on community building, we believe that XYZ token will become a major player in the cryptocurrency market. We invite you to join our ICO and be a part of this exciting project. */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
