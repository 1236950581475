import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Nav, Navbar, Container, Button, Overlay, Card }  from 'react-bootstrap';
import { Tooltip, Chart, ChartArea } from 'chart.js';
import { Line } from 'react-chartjs-2';
import dayjs from 'dayjs';

const CoinChart = (props) => {

  const [chartData, setChartData] = useState([])
  const [chartLabels, setChartLabels] = useState([])
  const [limit, setLimit] = useState(23);
  const [search, setSearch] = useState('histohour');
  const [percentDiff, setPercentDiff] = useState(0)

  const getDays = [];
  const setClose = [];
  const setTime = [];

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const ref = useRef(null);

  const handleClick1 = () => {
    setShow2(false);
    setShow1(!show1);
  };
  const handleClick2 = () => {
    setShow1(false);
    setShow2(!show2);
  };


  useEffect(() => {
    // console.log('props: ', 'https://min-api.cryptocompare.com/data/v2/histoday?fsym=' + props.coin + '&tsym=USD&limit=6')
    
     axios.get('https://min-api.cryptocompare.com/data/v2/' + search + '?fsym=' + props.coin + '&tsym=USD&limit=' + limit + '&api_key=ca0c75e0493e1c7b3016881cc0b6ca67f9195360661843f2ff3fa50675812b26')
    //  .then((resp) => resp)
     .then((response) => {
       console.log('DATA: ', response.data.Data.Data.length)
       response.data.Response != 'Error' ? 
       getDays.push(response.data.Data.Data) : console.log(response.data.Message)
        // response.map((days) => {
        //   console.log('days: ', days.data.Data.Data)
        //   setDays.push(days.data.Data.Data.close)
        // })

        // setTimeout(() => {setChartData(setDays)}, 1000)

        // console.log('getDays: ', getDays)
        // const diff = response.data.Data.Data;
        // const diffLength = response.data.Data.Data.length -1;

        // const diff_a = (diff[diffLength].close - diff[0].close);
        // const diff_b =  ((diff[diffLength].close + diff[0].close)/2);


        // // setPercentDiff(((diff[diff[diffLength - 1].close] - chartData[0].close) / diff[diff[diffLength - 1].close]) * 100)

        // console.log('percentDiff: ', diff[diffLength].close + ' - ' + diff[0].close);

        // console.log('Difference: ', diff_a + ' - ' + diff_b);

        

        getDays[0].map((week) => {
        // for (const obj of getDays) {
          // console.log('week: ', week.close)
          setClose.push(week.close)
          // setTime.push(dayjs(week.time * 1000).format('ddd'))
          if(search === 'histoday' && limit === 6) setTime.push(dayjs(week.time * 1000).format('MMM DD'))
          if(search === 'histoday' && limit === 30) setTime.push(dayjs(week.time * 1000).format('MMM DD'))
          if(search === 'histoday' && limit === 364) setTime.push(dayjs(week.time * 1000).format('MMM YYYY'))
          if(search === 'histohour') setTime.push(dayjs(week.time * 1000).format('h:mm A'))
          if(search === 'histominute') setTime.push(dayjs(week.time * 1000).format('h:mm A'))
        })
          setChartData([]);
          setChartData(setClose);
          setChartLabels(setTime);
          // setPercentDiff(((chartData[chartData.length - 1] - chartData[0]) / chartData[chartData.length - 1]) * 100)
          console.log('percent1: ', chartData[chartData.length - 1] )
          console.log('percent2: ', chartData[0] )
      })
      .catch((err) => {
        console.log('Error: ', err)
      })
      
  },[limit])

  console.log('chartData:', chartData);

  // const percentFirst = chartData[0];
  // const percentLast = chartData[chartData.length - 1];
  // const percentDiff = ((percentLast - percentFirst) / percentLast * 100);

  // console.log('percentDiff: ', percentDiff.atChar(0))

  //const percentDisplay

  const data = {
    labels: chartLabels,
    datasets: [
      {
        // label: '# of Votes',
        data: chartData,
        fill: false,
        backgroundColor: 'rgba(27, 63, 229, .5)',
        borderColor: 'rgba(27, 63, 229, .2)',
        lineTension: 0.4,
      },
    ],
  };

  Tooltip.positioners.myCustomPositioner = function(items) {
    const pos = Tooltip.positioners.average(items);

    return {

        x: pos.x,
        y: pos.y - pos.y - 100
        // xAlign: 'center',
        // yAlign: 'bottom',

        // You may also include xAlign and yAlign to override those tooltip options.
    };
};

  
//  useEffect(() => {
  let options = {
    scales: {
      y: {
        beginAtZero: false,
        display: true,
        grid: {
          color: '#ebebeb',
        },
        ticks: {
          
        }
      },
      x: {
        display: true, //this will remove all the x-axis grid lines
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          callback: function(val, index) {
            // Hide the label of every 2nd dataset
            return index % 3 === 0 ? this.getLabelForValue(val) : '';
          },
        }
      }
    },
    plugins: {
      legend: {
          display: false,
          // labels: {
          //     color: 'rgb(255, 99, 132)'
          // }
      },
      tooltip: {
        // plugins.tooltip.position = 'bottom';
        enabled: true,
        intersect: false,
        mode: "index",
        yAlign: 'bottom',
        callbacks: {
          title: function(tooltipItem) {
            console.log('tooltipItem:', tooltipItem[0].label)
            console.log('data:', data)
            return tooltipItem[0].label
          },
          // label: function(tooltipItem) {
          //   return tooltipItem.dataset.data[0];
          // },
          label: function(context) {
            let label = context.dataset.label || '';

            if (label) {
                label += ': ';
            }
            if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
            }
            return label;
        },
          afterLabel: function(tooltipItem, data) {
            var dataset = tooltipItem.dataset.data[0];
            // var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
            // return '(' + dataset + '%)';
          }
        },
        backgroundColor: '#FFF',
        border: 'solid 25px #000',
        borderRadius: '0px',
        titleFont: {size: '14px'},
        titleColor: '#000',
        bodyColor: '#000',
        borderColor: '#000',
        borderWidth: 1,
        bodyFont: {size: '12px'},
        displayColors: false,
        padding: 15,
        marginTop: '-300px',
        position: 'myCustomPositioner',
        width: '100%'
      },
      // tooltips: {
        
      //   intersect: false,
      // },
      hover: {
        mode: "nearest",
        intersect: true,
      },
  },
  animation: {
    duration: 500
  },
  };
//  }),[]

  const query = (limit, search) => {
    setLimit(limit);
    setSearch(search);

    // console.log('query: ', limit + '-' + search)
  }

  const high = Math.max.apply(Math, chartData);
  const low = Math.min.apply(Math, chartData);

  const getDiff = (chartData[0]);
  const getAvg = (chartData[0])/2;





  setTimeout(() => {setPercentDiff((getAvg / getDiff) * 100)})



  return (
    <>
    <div className="row">
      <div className="col-sm-12">
        <span className="bold">High: ${high}</span>
        <span className="ml15 bold">Low: ${low}</span>
        {/* {percentDiff && percentDiff > 0 ?
        <span className="ml15 bold green">{percentDiff.toFixed(4)}%</span>
        : <span className="ml15 bold red">{percentDiff.toFixed(4)}%</span>
        } */}
      </div>
    </div>
    <div className="row">
      <div className="col-sm-12 mt10">
      <div ref={ref}>
      {/* <Button className="btn-xsm" variant="primary" size="sm" onClick={handleClick1}>Buy / Sell</Button> <Button className="btn-xsm" variant="primary" size="sm" onClick={handleClick2}>Wallet</Button> */}

        {show1 &&
        <Card className="CardDetails" style={{ width: '300px', height: '500px', zIndex: '999' }}>
          <Card.Body>
            <Card.Title><span>Sponsored</span></Card.Title>
            <a href="https://shop.ledger.com/pages/ledger-nano-x?r=b64650b3e27a"><img width='100%' height='auto' src="http://affiliate.ledger.com/image/300/250" /></a>
          </Card.Body>
        </Card>
        }
        {show2 &&
        <Card className="CardDetails" style={{ width: '18rem' }}>
          <Card.Body>
            <Card.Title><span>Sponsored</span></Card.Title>
            <Card.Text>
              Wallet
            </Card.Text>
            <Button variant="primary">Go somewhere</Button>
          </Card.Body>
        </Card>
        }

    </div>
      </div>
    </div>
    <div className="row">
       <div className="col-sm-12">

       <Nav
        defaultActiveKey="day"
        // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
        className="justify-content-end"
        variant="pills"
      >
        <Nav.Item className="chartdates">
          <Nav.Link eventKey="hour" onClick={() => query(59, 'histominute')}>Last Hour</Nav.Link>
        </Nav.Item>
        <Nav.Item className="chartdates">
          <Nav.Link eventKey="day" onClick={() => query(23, 'histohour')}>Last Day</Nav.Link>
        </Nav.Item>
        <Nav.Item className="chartdates">
          <Nav.Link eventKey="week" onClick={() => query(6, 'histoday')}>Last Week</Nav.Link>
        </Nav.Item>
        <Nav.Item className="chartdates">
          <Nav.Link eventKey="month" onClick={() => query(30, 'histoday')}>Last Month</Nav.Link>
        </Nav.Item>
        <Nav.Item className="chartdates">
          <Nav.Link eventKey="year" onClick={() => query(364, 'histoday')}>Last Year</Nav.Link>
        </Nav.Item>
        {/* <Nav.Item>
          <Nav.Link eventKey="all">All Time</Nav.Link>
        </Nav.Item> */}
      </Nav>
      </div>
      </div>
      <div className='chartContainer'>
        <Line 
          data={data} 
          options={options} 
          height="70"
        />
      </div>
      </>
  )

}

export default CoinChart;