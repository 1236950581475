import { useState } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Clock from '../Count/Clock';
import contactlogo from '../../../../assets/llama_green_500.png';

import './style.scss';

export const Welcome = (props) => {

	return (
		<section id="welcome" className="section top">
			<div className="container">
				<AnimationOnScroll animateIn="animate__fadeIn">
					<>
						<div className="row main-intro">
							<div className="col-12">
								<h1 className="token">Llama, for effortless tipping and paying <i className="fa-solid fa-arrow-down"></i></h1>
							</div>
						</div>

						<div className="row row-2">
							<div className="col-lg-4 col-md-12 logo-section">
								<img className="llamaLogo" src={contactlogo} />
							</div>
							<div className="col-lg-8 col-md-12 text-section">
								<div className="row">
									<div className="col"><h3>Llama is a new cryptocurrency meme token that is all set to take the world by storm, inspired by the meme culture and aims to offer a more casual and playful approach to the traditional cryptocurrency world. </h3></div>
								</div>
								<div className="row">
									<div className="col-lg-6 col-md-12"></div>
									<a className="col-lg-6 col-md-12 btn btn-primary" href="#presale">Get Started</a>
								</div>

							</div>
						</div>
						<div className="row">
							<div className="col-lg-4 col-md-12"></div>
							<div className="col-lg-8 col-md-12">
								<div className="row">
									<div className="col-lg-6 col-md-12 logo-section-2">Llama is an innovative token that is built with a primary focus on providing a fun and lighthearted experience to its users. </div>
									<div className="col-lg-6 col-md-12"></div>
								</div>
							</div>
						</div>
					</>
				</AnimationOnScroll>
				<AnimationOnScroll animateIn="animate__fadeIn">
					<div className="row">
						<div className="col-12">
							{/* <h1 className="token shabo">What? Llama? Token? Tell me more...</h1> */}
							{/* <h1 className="token">Llama, for effortless tipping, trading, and paying.</h1> */}
							{/* <p className="text">
                <div className="highlight">Llama is a new cryptocurrency meme token that is all set to take the world by storm.</div> 
                <div className="sub">
                  <div><span>Llama is an innovative token that is built with a primary focus on providing a fun and lighthearted experience to its users.</span> It is inspired by the meme culture and aims to offer a more casual and playful approach to the traditional cryptocurrency world. The Llama team has put in extensive effort to create a user-friendly platform that is easy to navigate, even for those who are new to the cryptocurrency space.</div>
                  <a className="btn btn-sm btn-primary" href="#about">About<i className="fa-solid fa-angle-right"></i></a> <a className="btn btn-sm btn-light ml15" href="./LLamaWhitePaper.pdf" download>White Paper <i className="fa-solid fa-angle-right"></i></a>
                </div>
                </p> */}
						</div>
						{/* <div className="col-12">
                <div className='llamaCircle'></div>
                <img className="llamaLogo" src={contactlogo} />
              </div> */}
					</div>
				</AnimationOnScroll>
			</div>
		</section>
	)
}