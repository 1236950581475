import { useState, useEffect } from 'react';
import axios from 'axios';
import Posts from './blog';


const Blog = () => {
  const [getPosts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [getError, setError]=  useState()

  useEffect(() => {

    console.log('length 1: ', getPosts.length);
    axios
      .get('https://fathomless-taste.000webhostapp.com/wp-json/wp/v2/posts')
      .then(function(response) {
        console.log(response.data)
        setPosts(response.data)
        setLoading(false)
      })
      .catch(error => console.log(error))

      console.log('length 2: ', getPosts.length);
    
  }, []);

  return (
    <div className="container">
     <h2>The Llama Blog</h2>
     {!loading ?
      <div className="row">
          {getPosts.map((item) =>
            <div className="col-md-4 col-sm-12 blog-posts">
              
              <Posts
                post={item}
              />
              
            </div>
            )
          }
        </div>
      : <div className="col text-center bold">...LOADING POSTS</div>
      }
    </div>
  )
}

export default Blog;