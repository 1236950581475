import axios from "axios";
import React, { useEffect, useState } from "react";
import "./index.scss";

export default function Blog({ post }) {
  const [featuredImage, setFeaturedimage] = useState();

  const getImage = () => {
    axios
     .get(post?._links["wp:featuredmedia"][0]?.href)
     .then((response) => {
      setFeaturedimage(response.data.source_url);
    });
  };

  useEffect(() => {
    getImage();
  }, {});
  
  const shortExcerpt = post.excerpt.rendered.length > 200 ? post.excerpt.rendered.substring(0,200) + "..." : post.excerpt.rendered;

  return (
    <a href={'/blog/' + post.id + '/' + post.slug} >
    <div class="card">
      <img src={featuredImage} class="card-img-top" />
      <div className="card-body">
        <p className="blog-date">
          {new Date(post.date).toLocaleDateString("en-US", {
            day: "numeric",
            month: "long",
            year: "numeric",
            weekday: 'short',
          })}
        </p>
        <h2 className="card-title">{post.title.rendered}</h2>
        <p
          className="card-text"
          dangerouslySetInnerHTML={{ __html: shortExcerpt }}
        />
        <span className="btn btn-sm btn-primary">Read More <i className="fa-solid fa-angle-right ml10"></i></span>
      </div>
     </div>
     </a>
  );
}