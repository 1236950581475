import { Row, Col, Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';

const BottomChange = (props) => {

  console.log('props.top: ', props.top)
  
  return (
    <>
    <Row className="mt30"><Col><h5>Top Losers</h5></Col></Row>
    <Row>
      {props.top.map((data) => {
        return (
        <Col xs={6} lg={3}>
        <Link to={`/crypto-markets/${data.Name}`} className="toplist">
          <Card>
          <Card.Img variant="top" src={`http://cryptocompare.com/${data.Img}`} style={{width: '50px', margin: '20px auto 0'}}/>
            <Card.Body className="text-center">
              <Card.Title>{data.FullName}</Card.Title>
              <Card.Text>
                <Row className="mt30">
                  <Col className="bold">${data.Price.toFixed(4)}</Col>
                  <Col className="bold" style={{color: 'red'}}>{data.Change.toFixed(2)}%</Col>
              </Row>
              </Card.Text>
              {/* <Button variant="primary" size="sm">Go somewhere</Button> */}
            </Card.Body>
          </Card>
        </Link>
      </Col>
        )
      })}   
    </Row>
    </>
  )
}

export default BottomChange;