import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";
import "./Account.scss";
import { auth, db, logout } from "../../firebase";
function Logout() {
  // const [user, loading, error] = useAuthState(auth);
  // const [name, setName] = useState("");
  const history = useHistory();
  // const fetchUserName = async () => {
  //   try {
  //     const query = await db
  //       .collection("users")
  //       .where("uid", "==", user?.uid)
  //       .get();
  //     const data = await query.docs[0].data();
  //     setName(data.name);
  //     sessionStorage.setItem('userid', user?.uid);
  //     sessionStorage.setItem('name', data.name);
  //   } catch (err) {
  //     console.error(err);
  //     alert("An error occured while fetching user data");
  //   }
  // };
  // useEffect(() => {
  //   if (loading) return;
  //   if (!user) return history.replace("/");
  //   fetchUserName();
  // }, [user, loading]);

  useEffect(() => {
    // const sessionCookie = request.cookies.session || '';
    // sessionStorage.removeItem(*);
    sessionStorage.removeItem('userid');
    sessionStorage.removeItem('name');
    window.location = ('/');
  })
  
  return (
    <div className="dashboard">
      <div className="dashboard__container">
        Logged in as
        {/* <div>{name} {user?.uid}</div>
        <div>{user?.email}</div> */}
        <button className="dashboard__btn" onClick={logout}>
          Logout
        </button>
      </div>
    </div>
  );
}
export default Logout;