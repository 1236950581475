import './Roadmap.scss';
import { AnimationOnScroll } from 'react-animation-on-scroll';

export const Roadmap = (props) => {
  return (
    <section id='roadmap' className='text-center'>
      <div className='container'>
        <div className='col section-title'>
          <h2>Roadmap</h2>
        </div>
        <AnimationOnScroll animateIn="animate__fadeInUp" delay={500} style={{minHeight: '350px'}}>
        <div className='row'>
        <div className='col-xs-12 col-md-12'><h3 className="roadmapYear">2023</h3><hr></hr></div>
          <div className='col-xs-6 col-md-3 roadmap'>
            <div className='card'>
              <div className='card-title'><i className="fa fa-chart-simple"></i><h3>Q1</h3></div>
              <div className='card-body'>
                <p>Plan...Plan...Plan...</p>
              </div>
              <div class="card-footer success">
                Completed
              </div>
            </div>
          </div>
          <div className='col-xs-6 col-md-3 roadmap'>
            <div className='card'>
              <div className='card-title'><i className="fa fa-chart-simple"></i><h3>Q2</h3></div>
              <div className='card-body'>
                  <p>Keep on Planning, Keep on Planning...</p>
              </div>
              <div class="card-footer success">
                Completed
              </div>
            </div>
          </div>
          <div className='col-xs-6 col-md-3 roadmap'>
            <div className='card'>
              <div className='card-title'><i className="fa fa-chart-simple"></i><h3>Q3</h3></div>
              <div className='card-body'>
                <p>Complete initial planning and web app.</p>
                {/* <p>10,000+ Holders</p><p>Launch Llama dApps</p><p>Llama dApps are a Selection of Smart Contract dApps for developers and non-developers. Look for list of dApps in Q2</p><p>Want to See Specific dApps Offered? <a style={{fontWeight: 'bold'}} href="https://twitter.com/token_llama" target="_blank">Let Us Know!</a></p> */}
              </div>
              <div class="card-footer success">
                Completed
              </div>
            </div>
          </div>
          <div className='col-xs-6 col-md-3 roadmap'>
            <div className='card'>
              <div className='card-title'><i className="fa fa-chart-simple"></i><h3>Q4</h3></div>
              <div className='card-body'>
                <ul>
                  <li>Initiate Pre-Sale - Phase 1 and Phase 2</li>
                  <li>Launch Llama $Llama</li>
                  <li>Acquire 1000 Holders</li>
                  <li>Get Listed on Crypto Data Lists and News Sites/Apps</li>
                  {/* <li>Spread the Word About $Llama</li> */}
                </ul>
                <p>Wrap up 2023 and Ignite 2024!</p>
              </div>
              <div class="card-footer progress">
                In Progress
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
        <div className='col-xs-12 col-md-12 mt50'><h3 className="roadmapYear">2024</h3><hr></hr></div>
          <div className='col-xs-6 col-md-3 roadmap'>
            <div className='card'>
              <div className='card-title'><i className="fa fa-chart-simple"></i><h3>Q1</h3></div>
              <div className='card-body'>
                <p>Complete Pre-Sale</p>
                <p>2500+ Holders</p>
                <p></p>
Get Listed on Crypto Data Lists and News Sites/Apps

              </div>
            </div>
          </div>
          <div className='col-xs-6 col-md-3 roadmap'>
            <div className='card'>
              <div className='card-title'><i className="fa fa-chart-simple"></i><h3>Q2</h3></div>
              <div className='card-body'>
                <p>Keep building the Llama Community</p>
              <p>Spread the Word About $Llama</p>
              <p>Launch Llama dApps</p><p>Llama dApps are a Selection of Smart Contract dApps for developers and non-developers. Look for list of dApps in Q2</p><p>Want to See Specific dApps Offered? <a style={{fontWeight: 'bold'}} href="https://twitter.com/token_llama" target="_blank">Let Us Know!</a></p>
              
              </div>
            </div>
          </div>
          <div className='col-xs-6 col-md-3 roadmap'>
            <div className='card'>
              <div className='card-title'><i className="fa fa-chart-simple"></i><h3>Q3</h3></div>
              <div className='card-body'>
                <p>Build the LLlama to 25,000</p>
                <p>Launch the Llama Developer Tools</p>
              </div>
            </div>
          </div>
          <div className='col-xs-6 col-md-3 roadmap'>
            <div className='card'>
              <div className='card-title'><i className="fa fa-chart-simple"></i><h3>Q4</h3></div>
              <div className='card-body'>
                <p>Build the LLlama Community to 50,000</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{marginTop: '150px'}}>
            <div className="col">
            <a className="btn btn-primary" href="#team">Team<i className="fa-solid fa-angle-right ml10"></i></a>
            </div>

          </div>
        </AnimationOnScroll>
        {/* <div className='row mt50'>
        <div className='col-xs-12 col-md-12'><h3 className="roadmapYear">2024</h3><hr></hr></div>
          {props.data
            ? props.data.year2.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-xs-6 col-md-3 roadmap'>
                  {' '}
                  <div className='card card-body'>
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                  </div>
                </div>
              ))
            : 'Loading...'}
        </div> */}
      </div>
    </section>
  )
}
